import { createSlice } from "@reduxjs/toolkit";

export const draftSlice = createSlice({
    name: 'draft',
    initialState: [],
    reducers: {
        addDraft: (currentState, action) => {
            currentState.push(action.payload);
        },
        cleanDraft: ()=> {
            return []
        }
    }
})