import React from 'react'
import icon from '../../assets/images/BlueMixteLogo.png'
import { useSelector } from 'react-redux';
import { idStoreCurrent, magasins } from '../../redux/selectors';
export const Invoice = React.forwardRef((props, ref) => {
    
    const idStore = useSelector(idStoreCurrent);
    const listMagasin = useSelector(magasins);
    const currentStore = listMagasin?.find(item => item.id === idStore);

  return (
    <div className="card" ref={ref}>
        <div className="card-body" style={{fontFamily: 'Poppins'}}>
            <div className="row m-2">
                <div className="col-12 mb-2 text-center">
                    <img src={icon} height="60" alt=""/>
                </div>
                <div className="col-12 text-center">
                    <div style={{fontSize: '13px'}}>
                        <span className='strong'>{currentStore.nomStore}</span><br/>
                        {`${currentStore.repere}, ${currentStore.ville}`}<br/>
                        {`${currentStore.etat}, ${currentStore.pays}`}<br/>
                        Email: {currentStore.email}<br/>
                        Tel: {currentStore.tel}<br/>
                        Client: <span className='strong'>{props.customer}</span><br/>
                        Vendu par: <span className='strong'>{props.salesPerson}</span><br/>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className='h3'>Invoice INV-00123</div>
                </div>
            </div>
            <div className='table-responsive d-flex align-items-center m-1'>
                <table className="table table-transparent" style={{width: '100%', fontSize: '12px'}} >
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="text-center">Qnt</th>
                        <th className="text-end">Unit</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {props.list?.map((item, i)=> (
                        <tr key={i}>
                            <td>
                                <p className="strong mb-1">{item.title}</p>
                                <div className="text-muted">{item.category}</div>
                            </td>
                            <td className="text-center">{item.qty}x</td>
                            <td className="text-end">${item.price}</td>
                            <td className="text-end">${item.price*item.qty}</td>
                        </tr>
                    ))
                    }

                    <tr>
                        <td colspan="3" className="strong text-end">Items</td>
                        <td className="text-end">{props.item}x</td>
                    </tr>
                    {props.taxes!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Taxes</td>
                        <td className="text-end">${props.taxes}</td>
                    </tr>}
                    {props.discount!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Discount</td>
                        <td className="text-end">${props.discount}</td>
                    </tr>}

                    {props.montantVerser!==0 && props.renduMonnaie()!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Montant verser</td>
                        <td className="text-end">${props.montantVerser}</td>
                    </tr>}
                    {props.montantDue()!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Balance</td>
                        <td className="text-end">${props.montantDue()}</td>
                    </tr>}
                    {props.renduMonnaie()!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Monnaie</td>
                        <td className="text-end">${props.renduMonnaie()}</td>
                    </tr>}
                    <tr>
                        <td colspan="3" className="strong text-end">Subtotal</td>
                        <td className="text-end">${props.totalSale}</td>
                    </tr>
                    <tr>
                        <td colspan="3" className="font-weight-bold text-uppercase text-end">Total</td>
                        <td className="font-weight-bold text-end">
                            ${(parseFloat(props.totalSale)+parseFloat(props.taxes)) - parseFloat(props.discount)}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <p className="text-muted text-center mt-3 m-2" style={{fontSize: '13px'}} >
                {/* Thank you very much for doing business with us. We look forward to working with
                you again! */}
                Merci de nous avoir visité, À bientôt!
            </p>
        </div>
    </div>
  )
});