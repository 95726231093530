import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../../components/header-page/Header';
import { ToastContext } from '../../../../utils/contexts/context';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { randomNumberInRange } from '../../../../utils';
import { clientSchema } from '../../../../utils/schemas/clientSchema';
import { useCreateClientMutation } from '../../../../utils/services/api/clientApi';

function AddClient() {

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  let code = `Cl-${randomNumberInRange(100, 1000)}`;

  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: zodResolver(clientSchema),
    defaultValues: {
      type_client: 'particulier',
      nom: '',
      prenom: '',
      email: '',
      tel: 0,
      adresse: '',
    }
  })

  const [createClient] = useCreateClientMutation();

  const onSubmit = (data)=> {
    let client = {
      ...data,
      code: code,
    }

    createClient(client)
    .then((resp)=> {
      if(resp.data.message){ 
        setToast({
          ...toast,
          isOpen: true,
          severity: 'success',
          message: resp.data.message
        })
        navigate('/clients/list');
      }else{
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.errors[0].message
        })
      }
    }).catch((error)=> {
      console.log(error)
    })
  }

  return (
    <div className="page-wrapper">

        <Header title={'Ajouter Client'}/>

        <div className="page-body">
          <div className="container-xl">
            <div className="card">

              <div className="card-header">
                <h3 className="card-title">Contact</h3>
              </div>
              
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  <div className="row row-cards">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" defaultValue={code} disabled={true}/>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Nom</label>
                        <input type="text" className={`form-control ${errors.nom && 'is-invalid'}`} placeholder="nom" {...register('nom')}/>
                        {errors.nom && <div class="invalid-feedback">{errors.nom.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Prenom</label>
                        <input type="text" className={`form-control ${errors.prenom && 'is-invalid'}`} placeholder="prenom" {...register('prenom')} />
                        {errors.prenom && <div class="invalid-feedback">{errors.prenom.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Type Client</label>
                        <select className={`form-control form-select ${errors.type_client && 'is-invalid'}`}  {...register('type_client')}>
                          <option></option>
                          <option value="particulier">Particulier</option>
                          <option value="entreprise">Entreprise</option>
                        </select>
                        {errors.type_client && <div class="invalid-feedback">{errors.type_client.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder="customer@gmail.com" {...register('email')}/>
                        {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Tel</label>
                        <input type="text" className={`form-control ${errors.tel && 'is-invalid'}`} {...register('tel', {valueAsNumber: true})}/>
                        {errors.tel && <div class="invalid-feedback">{errors.tel.message}</div>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Address</label>
                        <input type="text" className={`form-control ${errors.adresse && 'is-invalid'}`} placeholder="Rue 2L, Cap-Haitien, Haiti" {...register('adresse')}/>
                        {errors.adresse && <div class="invalid-feedback">{errors.adresse.message}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                  <div class="d-flex">
                    <Link to="/clients/list" class="btn btn-link">Cancel</Link>
                    <button type="submit" class="btn btn-primary ms-auto">Valider</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
    </div>
  )
}

export default AddClient