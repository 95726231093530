import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../../environments/environments';

const url = env;

const roleApi = createApi({
    reducerPath: 'roleApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['Role'],
    endpoints: (builder) => ({
        getRoles : builder.query({
            query: (filter) => ({
                url: '/roles',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Role']
        }),

        getOneRole : builder.query({
            query: (id) => ({
                url: `/roles/${id}`,
                method: 'GET'
            }),
            providesTags: ['Role']
        }), 

        createRole : builder.mutation({
            query: (role) => ({
                url: '/roles/create',
                method: 'POST',
                body: role
            }),
            invalidatesTags: ['Role']
        }),

        updateRole : builder.mutation({
            query: ({id, role}) => ({
                url: `/roles/${id}`,
                method: 'PUT',
                body: role
            }),
            invalidatesTags: ['Role']
        }),

        deleteRole : builder.mutation({
            query: (id) => ({
                url: `/roles/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Role']
        })
    })
})

export const {
    useGetRolesQuery,
    useGetOneRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation
} = roleApi;

export default roleApi;