import Ribbon from './Ribbon';

function Card(props) {
  return (
    <div className={`card card-sm ${props.suiviStock && props.enStock===0 && 'card-inactive'}`} style={{height: '290px'}}>
        <img src={props.cover} alt="cover" className="card-img-top" style={{height: '180px', objectFit: 'cover'}}/>
        <div className="card-body">
        <div className="d-flex align-items-center">
            <div>
                <div>{props.title} <br/> <span style={{fontSize: '12px'}}>{props.category}</span></div>
                <span style={{fontSize: '11px'}}>{props.variante}</span>
                <div className="text-muted">{props.price}$  {props.suiviStock && <span style={{fontSize: '10px', color: '#a52a2a', fontWeight: 500}}>En stock: {props.enStock}x</span>}</div>
            </div>
            <div className="ms-auto">
                <button className='btn btn-primary btn-icon' style={{ marginRight: '7px' }} onClick={props.onSelect}>
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-shopping-cart"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17h-11v-14h-2" /><path d="M6 5l14 1l-1 7h-13" /></svg>
                </button>
            </div>
        </div>
        </div>

        {props.suiviStock && props.enStock===0 && <Ribbon type={`rupture`}/>}
        {props.suiviStock && props.enStock===props.stockFaible && <Ribbon type={`faible`}/>}
    </div>
  )
}

export default Card