import React from 'react'

function Badge({color, children}) {

    const colorBadge = (color)=>{

        switch(color){
            case 'primary':
                return "badge bg-blue badge-notification badge-pill text-white"
            case 'secondary':
                return "badge bg-purple badge-notification badge-pill text-white"
            case 'error':
                return "badge bg-red badge-notification badge-pill text-white"
            case 'info':
                return "badge bg-azure badge-notification badge-pill text-white"
            case 'success':
                return "badge bg-green badge-notification badge-pill text-white"
            case 'warning':
                return "badge bg-orange badge-notification badge-pill text-white"
            default:
                return "badge bg-white badge-notification badge-pill"
        }
    }

  return (
    <span className={colorBadge(color)}>
        {children}
    </span>
  )
}

export default Badge