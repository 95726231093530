import React, { useEffect, useState } from 'react';
import Header from '../../../components/header-page/Header';
import Status from '../../../components/tabler/Status';
import { useGetOrdersQuery } from '../../../utils/services/api/orderApi';
import { generatePagination } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import DeleteOrder from './mutation/DeleteOrder';
import ViewOrder from './query/ViewOrder';
import { useDebouncedCallback } from 'use-debounce';

function Orders() {

  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    query: ''
  })

  const handleSearch = useDebouncedCallback((e) =>{
    e.preventDefault();
    setFilter({...filter, query: e.target.value})
  }, 100);

  useEffect(()=> {
    setCurrentPage(1)
  }, [filter])

  const {data: orders, isLoading} = useGetOrdersQuery(filter);

  // Pour exporter en format Excel
  const handleExport = ()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(orders);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Liste_produits.xlsx');
  }

  
  // Pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = orders?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(orders?.length/recordsPerPage);
  const numbers = generatePagination(currentPage, npage)

  //  Pour les lignes selectionnées
  const [selected, setSelected] = useState([]);

  const handlePayment =(id)=> {
    // navigate(`/payment/add/${id}`)
  }

  const [idToView, setIdToView] = useState(0);
  const handleView = (id) => {
    setIdToView(id)
  }

  return (
    <div className="page-wrapper">
        
        <Header title={"Liste des Ventes"}/>

        <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Factures</h3>
                    <div className='ms-auto'>
                      {selected.length > 0 &&
                        <button className='btn btn-icon btn-ghost-danger' data-bs-toggle="modal" data-bs-target="#modal-danger" style={{ marginRight: '5px' }}>
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                        </button>
                      }

                      <button className='btn btn-icon' data-bs-toggle="modal" data-bs-target="#modal-team" style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-filter"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" /></svg>
                      </button>

                      <button className='btn btn-icon btn-success' onClick={()=>handleExport()} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Exportez en format Excel" style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-spreadsheet"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M8 11h8v7h-8z" /><path d="M8 15h8" /><path d="M11 11v7" /></svg>
                      </button>

                    </div>
                  </div>
                  <div className="card-body border-bottom py-3">
                    <div className="d-flex">
                      <div className="text-muted">
                        Show
                        <div className="mx-2 d-inline-block">
                            <select class="form-select" className="form-control form-control-sm" onChange={(e)=> setRecordsPerPage(e.target.value)}>
                              <option value={10}>10</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                        </div>
                        entries
                      </div>
                      <div className="ms-auto text-muted">
                        Search:
                        <div className="ms-2 d-inline-block">
                         <input type="text" className="form-control form-control-sm" 
                          onChange={handleSearch}
                          aria-label="Search invoice"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table card-table table-vcenter text-nowrap datatable">
                      <thead>
                        <tr>
                          <th className="w-1">
                            <input className="form-check-input m-0 align-middle" type="checkbox" 
                            checked={selected.length === orders?.length && orders?.length>0} 
                            onChange={(event) => {
                              setSelected(
                                event.target.checked ? orders?.map((row) => row.id) : [],
                              );
                            }}
                            aria-label="Select all invoices"/>
                          </th>
                          <th>Code</th>
                          <th>Client</th>
                          <th>Vendeur</th>
                          <th>Montant</th>
                          <th>Quantité</th>
                          <th>Balance</th>
                          <th>Statut</th>
                          <th>Date vente</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoading && 
                          <tr>
                            <td colspan={10} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                          </tr>
                        }
                        {records?.map((order, i)=>(
                        <tr key={i}>
                          <td><input className="form-check-input m-0 align-middle" type="checkbox" 
                          checked={selected.includes(order.id)}
                          onChange={(event) => {
                            setSelected((ids) =>
                              event.target.checked
                                ? ids.concat(order.id)
                                : ids.filter((itemId) => itemId !== order.id),
                            );
                          }}
                          aria-label="Select invoice"/></td>
                          <td>{order.noFacture}</td>
                          <td>
                            <div className="d-flex py-1 align-items-center">
                              <div className="flex-fill">
                                <div className="font-weight-medium">{order.fullnameClient}</div>
                                <div className="text-muted">{order.telClient}</div>
                              </div>
                            </div>
                          </td>
                          <td>
                              <div className="d-flex py-1 align-items-center">
                                <span className="avatar me-2" style={{backgroundImage: `url(${order.imageUrl})`}}></span>
                                <div className="flex-fill">
                                  <div className="font-weight-medium">{order.username}</div>
                                  <div className="text-muted">{order.telUser}</div>
                                </div>
                              </div>
                          </td>
                          <td>{(order.montantVendu + order.taxes) - order.discount} gdes</td>
                          <td>{order.items}x</td>
                          <td>{order.balance} gdes</td>
                          <td>
                            <Status color={order.statut==='paid' ? 'green' : 
                              (order.statut==='due' ? 'red' : 'yellow')}>
                                {order.statut==='paid' ? 'Payé' : 
                              (order.statut==='due' ? 'Dette' : 'Balance')}
                            </Status>
                          </td>
                          <td>{dayjs(order.dateVente).format('DD/MM/YYYY')}</td>
                          <td className="text-end">
                              <button className="btn btn-icon btn-ghost-secondary" onClick={()=> handleView(order.id)} data-bs-toggle="offcanvas" href="#offcanvasEnd" aria-controls="offcanvasEnd">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-eye"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                              </button>
                          </td>
                        </tr>
                        ))}

                       {orders?.length === 0 &&
                          <tr>
                            <td colspan={10} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer d-flex align-items-center">
                    <p className="m-0 text-muted">Showing <span>{currentPage}</span> to <span>{npage}</span></p>
                    <ul className="pagination m-0 ms-auto">
                      <li className="page-item">
                        <button onClick={prePage} className={`page-link ${currentPage===1 && 'disabled'}`} tabindex="-1">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                        </button>
                      </li>
                      {!isLoading && (numbers?.map((page, id)=> (
                        <li className={`page-item ${currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
                      )))}
                      <li className="page-item">
                        <button onClick={nextPage} className={`page-link ${currentPage===npage && 'disabled'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <ViewOrder id={idToView}/>
        <DeleteOrder ids={selected} setIds={setSelected}/>
    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }

}

export default Orders