import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../../../components/header-page/Header'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { userSchema } from '../../../../utils/schemas/userSchema'
import { useGetRolesQuery } from '../../../../utils/services/api/roleApi'
import { useGetMagasinsQuery } from '../../../../utils/services/api/magasinsApi'
import { useCreateUserMutation } from '../../../../utils/services/api/userApi'
import { ToastContext } from '../../../../utils/contexts/context'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function AddUser() {

  // Pour le composant select
  const animatedComponents = makeAnimated();

  const [toast, setToast] = useContext(ToastContext);

  const navigate = useNavigate();

  const {data: roles} = useGetRolesQuery();

  const {data: magasins} = useGetMagasinsQuery();
  const options = magasins?.map(store => ({
    value: store.id,
    label: store.nomStore
  }))

  const {register, handleSubmit, control, formState: {errors}} = useForm({
    resolver: zodResolver(userSchema)
  })

  const [createUser] = useCreateUserMutation()

  const onSubmit = (data) => {
    const dataUser = new FormData()
    dataUser.append('nom',data.nom);
    dataUser.append('prenom',data.prenom);
    dataUser.append('email',data.email);
    dataUser.append('tel',data.tel);
    dataUser.append('username',data.username);
    dataUser.append('password',data.password);
    dataUser.append('statut',data.statut);
    dataUser.append('imageUrl',data.imageUrl[0]);
    dataUser.append('magasins',JSON.stringify(data.magasins));
    dataUser.append('roleId',data.roleId);

    createUser(dataUser)
    .then((resp)=>{
      if(resp.data.message){
        setToast({
          ...toast,
          isOpen: true,
          severity: 'success',
          message: resp.data.message
        })

        navigate('/utilisateurs/list')
      }else if(resp.data.error){
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.error
        })
      }else{
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.errors[0].message
        })
      }
    }).catch((error)=> {
      console.log(error)
    })
  }

  return (
    <div className="page-wrapper">

        <Header title={'Ajouter Utilisateur'}/>

        <div className="page-body">
          <div className="container-xl">
            <div className="card">

              <div className="card-header">
                <h3 className="card-title">Employé(e)</h3>
              </div>
              
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  <div className="row row-cards">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Nom</label>
                        <input type="text" className={`form-control ${errors.nom && 'is-invalid'}`} placeholder="nom" {...register('nom')}/>
                        {errors.nom && <div class="invalid-feedback">{errors.nom.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Prenom</label>
                        <input type="text" className={`form-control ${errors.prenom && 'is-invalid'}`} placeholder="prenom" {...register('prenom')}/>
                        {errors.prenom && <div class="invalid-feedback">{errors.prenom.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Role</label>
                        <select className={`form-control form-select ${errors.roleId && 'is-invalid'}`}  {...register('roleId', {valueAsNumber: true})}>
                        <option></option>
                        {roles?.map((role, i)=>
                          (
                            <option key={i} value={role.id}>{role.nom}</option>
                          ))}
                      </select>
                      {errors.roleId && <div class="invalid-feedback">{errors.roleId.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-5">
                      <div className="mb-3">
                        <label className="form-label">email</label>
                        <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder="email" {...register('email')}/>
                        {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Telephone</label>
                        <input type="number" className={`form-control ${errors.tel && 'is-invalid'}`} placeholder="Company" {...register('tel', {valueAsNumber: true})}/>
                        {errors.tel && <div class="invalid-feedback">{errors.tel.message}</div>}
                      </div>
                    </div>
                    
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Magasins</label>
                        {/* <select className={`form-control form-select ${errors.magasinId && 'is-invalid'}`}  {...register('magasinId', {valueAsNumber: true})} >
                          <option></option>
                          {magasins?.map((magasin, i)=>
                            (
                              <option key={i} value={magasin.id}>{magasin.nomStore}</option>
                            ))}
                        </select>
                        {errors.magasinId && <div class="invalid-feedback">{errors.magasinId.message}</div>} */}
                        <Controller
                          name='magasins'
                          control={control}
                          defaultValue={[]}
                          render={({field})=> (
                            <Select
                              {...field}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              defaultValue={[]}
                              isMulti
                              options={options}
                              value={options?.filter(option => field.value.includes(option.value))}
                              onChange={(selected)=> {
                                const ids = selected.map(options => Number(options.value));
                                field.onChange(ids)
                              }}
                            />
                          )}
                        />
                        {errors.magasins && <div class="invalid-feedback">{errors.magasins.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-5">
                      <div className="mb-3">
                        <label className="form-label">Nom d'utlisateur</label>
                        <input type="text" className={`form-control ${errors.username && 'is-invalid'}`} placeholder="username" {...register('username')}/>
                        {errors.username && <div class="invalid-feedback">{errors.username.message}</div>}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="mb-3">
                        <label className="form-label">Mot de passe</label>
                        <input type="password" className={`form-control ${errors.password && 'is-invalid'}`} placeholder="mot de passe" {...register('password')}/>
                        {errors.password && <div class="invalid-feedback">{errors.password.message}</div>}
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="mb-3 mb-0">
                        <label className="form-label">Image</label>
                        <input type="file" class={`form-control ${errors.imageUrl && 'is-invalid'}`} {...register('imageUrl')}/>
                        {errors.imageUrl && <div class="invalid-feedback">{errors.imageUrl.message}</div>}
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-label">Statut</div>
                      <label className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" {...register('statut')}/>
                        <span className="form-check-label">Activation compte utilisateur</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                    <div class="d-flex">
                      <Link to="/utilisateurs/list" class="btn btn-link">Annuler</Link>
                      <button type="submit" class="btn btn-primary ms-auto">Valider</button>
                    </div>
                  </div>
              </form>

            </div>
          </div>
        </div>
    </div>
  )
}

export default AddUser