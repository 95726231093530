import dayjs from 'dayjs';
import Status from '../../../../components/tabler/Status';
import { useGetOneMagasinQuery } from '../../../../utils/services/api/magasinsApi';

function ViewMagasin({id}) {

    const {data: magasin} = useGetOneMagasinQuery(id);

  return (
    <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEnd" aria-labelledby="offcanvasEndLabel">
        <div className="offcanvas-header">
            <h2 className="offcanvas-title" id="offcanvasEndLabel">Magasin {magasin?.id}</h2>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div>
                <div className="card">
                    <div className="card-header">
                    <h3 className="card-title">Details: {magasin?.ref}</h3>
                    </div>
                    <div className="list-group list-group-flush list-group-hoverable">
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate">
                                <div className="text-reset d-block">Nom Magasin</div>
                                <div className="d-block text-muted text-truncate mt-n1">{magasin?.nomStore}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset ">Departement</div>
                            <div className=" text-muted text-truncate mt-n1">{magasin?.etat}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Ville</div>
                                <div className="text-muted text-truncate mt-n1">{magasin?.ville}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset">Répère</div>
                            <div className="text-muted text-truncate mt-n1">{magasin?.repere}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Code Postal</div>
                                <div className="text-muted text-truncate mt-n1">{magasin?.codePostal}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset">Pays</div>
                            <div className="text-muted text-truncate mt-n1">{magasin?.pays}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset">Telephone</div>
                            <div className="text-muted text-truncate mt-n1">{magasin?.tel}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset">Email</div>
                            <div className="text-muted text-truncate mt-n1">{magasin?.email}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Statut</div>
                                <div className="text-muted text-truncate mt-n1">
                                    <Status color={magasin?.statut ? 'green' : 'red'}>{magasin?.statut ? 'Ouvert' : 'Fermé'}</Status>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Date d'enregistrement</div>
                                <div className="text-muted text-truncate mt-n1">{dayjs(magasin?.createdAt).format('DD-MM-YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewMagasin