import CardReportSkeleton from "../../../../components/skeleton/CardReportSkeleton"
import MotionNumber from 'motion-number';

function TotalVente({isLoading, vente, duree}) {

  if(isLoading){
    return(
      <CardReportSkeleton/>
    )
  }

  return (
    <div className="card card-stats mb-xl-0">
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Total Ventes</h5>
                    <span className="h2 font-weight-bold mb-0">
                      <MotionNumber
                        value={vente}
                        format={{notion: 'compact'}}
                        locales="en-US"
                      />
                    </span>
                </div>
                <div className="col-auto">
                    <span className="bg-twitter text-white avatar">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-shopping-cart"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17h-11v-14h-2" /><path d="M6 5l14 1l-1 7h-13" /></svg>
                    </span>
                </div>
            </div>
            <p className="mt-5 mb-0 text-muted text-sm">
                <span className="text-nowrap">Depuis {duree}</span>
            </p>
        </div>
    </div>
  )
}

export default TotalVente