import Barcode from 'react-barcode';
import {useRef} from 'react';
import { useReactToPrint } from 'react-to-print';

function ViewBarCode(props) {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

  return (
    <div className="modal modal-blur fade" id="modal-simple" tabindex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Etiquette</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div style={{textAlign: 'center', margin: '0px 20px', border: '1px solid black', padding: '10px 20px'}} ref={componentRef}>
                {props.show.viewNomStore &&
                    <div style={{ fontSize: props.show.sizeNomStore, fontWeight: 600}}>{props.product.nomStore}</div>
                }
                {props.show.viewNomProduct &&
                    <div style={{ fontSize: props.show.sizeNomProduct, fontWeight: 500}}>{props.product.nomArticle}</div>
                }
                {props.show.viewVarianteProduct &&
                    <div style={{ fontSize: props.show.sizeVarianteProduct, fontWeight: 500}}>{props.product.variante}</div>
                }
                {props.show.viewPriceProduct &&
                    <div style={{ fontSize: props.show.sizePriceProduct, fontWeight: 400}}>{props.product.prixVente}</div>
                }
                <Barcode 
                    value={props.code}
                    format='CODE128'
                    width={2}
                    height={70}
                    displayValue={true}
                />
            </div>
          </div>
          <div className="modal-footer">
            {/* <button type="button" className="btn me-auto" data-bs-dismiss="modal">Close</button> */}
            <button type="button" onClick={()=> handlePrint()}className="btn btn-primary" data-bs-dismiss="modal">Imprimer</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewBarCode