import icon from '../../assets/images/BlueMixteLogo.png'

function PageLoader() {
  return (
    <div className="d-flex flex-column" style={{paddingTop: '200px'}}>
      <div className="page page-center">
        <div className="container container-slim py-10">
          <div className="text-center">
            <div className="mb-3">
              <div href="." className="navbar-brand navbar-brand-autodark"><img src={icon} height="50" alt=""/></div>
            </div>
            <div className="text-muted mb-3">Preparing Pos</div>
            <div className="progress progress-sm">
              <div className="progress-bar progress-bar-indeterminate"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageLoader