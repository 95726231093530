import React from 'react'
import Header from '../../../components/header-page/Header'
import Profil from './components/Profil'
import Fiche from './components/Fiche'
import Features from './components/Features'
import Notification from './components/Notification'
import TabSettings from './components/TabSettings'

function Settings() {
  return (
    <div className="page-wrapper">
      <Header title='Paramètres du système'/>
      <div className="page-body">
        <div className="container-xl">
          <div className="card">
            <div className="row g-0">
              <div className="col-3 d-none d-md-block border-end">
                <div>
                  <TabSettings/>
                </div>
              </div>
              <div className="col">
                <div className="card-body">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="content-account">
                      <Profil />
                    </div>
                    <div className="tab-pane fade" id="content-fiche">
                      <Fiche />
                    </div>
                    <div className="tab-pane fade" id="content-notifications">
                      <Notification />
                    </div>
                    <div className="tab-pane fade" id="content-features">
                      <Features />
                    </div>
                  </div>
                </div>
                {/* <div className="card-footer bg-transparent mt-auto">
                  <div className="btn-list justify-content-end">
                    <button className="btn">Cancel</button>
                    <button className="btn btn-primary">Submit</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings