import {useContext} from 'react';
import Header from '../../../../components/header-page/Header'; 
import { Link, useNavigate } from 'react-router-dom';
import { useCreateProductMutation } from '../../../../utils/services/api/productApi';
import { useGetCategoriesQuery } from '../../../../utils/services/api/categoryApi';
import { useGetMagasinsQuery } from '../../../../utils/services/api/magasinsApi';
import {useForm} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { articleSchema } from '../../../../utils/schemas/articleSchema';
import { randomNumberInRange } from '../../../../utils';
import { ToastContext } from '../../../../utils/contexts/context';
import BarcodeReader from 'react-barcode-reader';
import AddCategory from '../../categories/mutation/AddCategory';
// import { env } from "../../../../utils/environments/environments";

function AddArticle() {

  // const url = env;

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  let SKU = `SKU-${randomNumberInRange(100, 1000)}`;

  const { register, handleSubmit, formState: { errors }, setValue} = useForm({
    resolver: zodResolver(articleSchema),
    defaultValues: {
      nomArticle: "",
      variante: "",
      typeProduit: "",
      prixVente: 0,
      suiviStock: false,
      enStock: 0,
      stockFaible: 0,
      description: "",
      imageUrl: {},
      statut: true,
    }
  });

  // ******** Barcode ********
  // const findProductOnline = async (barcode) =>{
  //   try{
  //     const response = await fetch(`${url}/articles/scan/barcode?barcode=${barcode}`);

  //     const data = await response.json();
  //     if(data.total === 1){
  //       setValue('nomArticle', data.title);
  //       setValue('imageUrl', data.image);
  //       setValue('description', data.description);
  //     }else{
  //       setToast({
  //         ...toast,
  //         isOpen: true,
  //         severity: 'danger',
  //         message: data.message
  //       })
  //       setValue('nomArticle', "");
  //       setValue('imageUrl', {});
  //       setValue('description', "");
  //     }
      
  //   }catch(error){
  //     console.error('Error de recuperation des informations sur le produit:', error.message)
  //   }
  // }

  const handleScan = (data) => {
    setValue("barcode", data);
    // findProductOnline(data)
  };

  const handleError = (err) => {
    console.error(err);
  };
  // **** End Barcode *****

  const [createProduct] = useCreateProductMutation();
  const{data: categories} = useGetCategoriesQuery();
  const {data: magasins} = useGetMagasinsQuery();

  const onSubmit = (data) => {
    const dataArticle = new FormData()
      dataArticle.append('SKU',SKU);
      dataArticle.append('barcode',data.barcode);
      dataArticle.append('nomArticle',data.nomArticle);
      dataArticle.append('categoryId',data.categoryId);
      dataArticle.append('magasinId',data.magasinId);
      dataArticle.append('variante',data.variante);
      dataArticle.append('typeProduit',data.typeProduit);
      dataArticle.append('prixVente',data.prixVente);
      dataArticle.append('prixAchat',data.prixAchat);
      dataArticle.append('suiviStock',data.suiviStock);
      dataArticle.append('enStock',data.enStock);
      dataArticle.append('stockFaible',data.stockFaible);
      dataArticle.append('description',data.description);
      // Vérifier et ajouter l'image
    if (data.imageUrl && typeof data.imageUrl === 'object' && data.imageUrl.length > 0) {
      dataArticle.append('imageUrl', data.imageUrl[0]); // Fichier sélectionné
    } else if (typeof data.imageUrl === 'string') {
      dataArticle.append('imageUrl', data.imageUrl); // URL de l'image récupérée
    }
      dataArticle.append('statut',data.statut);

    createProduct(dataArticle)
    .then((resp)=> {
      if(resp.data.message){ 
        setToast({
          ...toast,
          isOpen: true,
          severity: 'success',
          message: resp.data.message
        })
        navigate('/articles/list');
      }else{
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.errors[0].message
        })
      }
    }).catch((error)=> {
      console.log(error)
    })
  }

  return (
    <div className="page-wrapper">

        <Header title={'Ajouter Produit'}/>

        <div className="page-body">
          <div className="container-xl">
            <div className="card">

              <div className="card-header">
                <h3 className="card-title">Stock</h3>
              </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <BarcodeReader
                onError={handleError}
                onScan={handleScan}
              />
              <div className="card-body">
                <div className="row row-cards">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label">SKU</label>
                      <input type="text" className="form-control" defaultValue={SKU} disabled={true} placeholder="SKU" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Barcode</label>
                      <input type="text" className="form-control" 
                        {...register("barcode")}
                        placeholder='Scanner le produit'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Nom Produit</label>
                      <input type="text" 
                        className={`form-control ${errors.nomArticle && 'is-invalid'}`} {...register('nomArticle')}
                      />
                      {errors.nomArticle && <div class="invalid-feedback">{errors.nomArticle.message}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Categories</label>
                      <div className='input-group mb-2'>
                        <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#modal-addCategory">+</button>
                        <select className={`form-control form-select ${errors.categoryId && 'is-invalid'}`}  {...register('categoryId', {valueAsNumber: true})}>
                          <option></option>
                          {categories?.map((category, i)=>
                            (
                              <option key={i} value={category.id}>{category.nomCategorie}</option>
                            ))}
                        </select>
                      </div>
                      {errors.categoryId && <div class="invalid-feedback">{errors.categoryId.message}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Magasins</label>
                      <select className={`form-control form-select ${errors.magasinId && 'is-invalid'}`}  {...register('magasinId', {valueAsNumber: true})}>
                        <option></option>
                        {magasins?.map((store, i)=>(<option key={i} value={store.id}>{store.nomStore}</option>))}
                      </select>
                      {errors.magasinId && <div class="invalid-feedback">{errors.magasinId.message}</div>}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Variante</label>
                      <input type="text" className={`form-control ${errors.variante && 'is-invalid'}`} placeholder="la variante du produit" {...register('variante')}/>
                      {errors.variante && <div class="invalid-feedback">{errors.variante.message}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Type Produit</label>
                      <select className={`form-control form-select ${errors.typeProduit && 'is-invalid'}`}  {...register('typeProduit')}>
                        <option></option>
                        <option value="physical">Physique</option>
                        <option value="digital">Digital</option>
                        <option value="service">Service</option>
                      </select>
                      {errors.typeProduit && <div class="invalid-feedback">{errors.typeProduit.message}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="mb-3">
                      <label className="form-label">Prix vente</label>
                      <input type="number" className={`form-control ${errors.prixVente && 'is-invalid'}`}  {...register('prixVente', {valueAsNumber: true})} placeholder="Gdes"/>
                      {errors.prixVente && <div class="invalid-feedback">{errors.prixVente.message}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-2">
                    <div className="mb-3">
                      <label className="form-label">Stock</label>
                      <label className="form-check">
                        <input className="form-check-input" type="checkbox" {...register('suiviStock')}/>
                        <span className="form-check-label">
                          Gérer stock
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-5">
                    <div className="mb-3">
                      <label className="form-label">En stock</label>
                      <input type="number" className={`form-control ${errors.enStock && 'is-invalid'}`} {...register('enStock', {valueAsNumber: true})} placeholder="En stock"/>
                      {errors.enStock && <div class="invalid-feedback">{errors.enStock.message}</div>}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="mb-3">
                      <label className="form-label">Stock faible</label>
                      <input type="number" className={`form-control ${errors.stockFaible && 'is-invalid'}`} {...register('stockFaible', {valueAsNumber: true})} placeholder="En stock"/>
                      {errors.stockFaible && <div class="invalid-feedback">{errors.stockFaible.message}</div>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 mb-0">
                      <label className="form-label">Image</label>
                      <input type="file" className="form-control" {...register('imageUrl')}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 mb-0">
                      <label className="form-label">Description</label>
                      <textarea rows="5" className={`form-control ${errors.description && 'is-invalid'}`} {...register('description')} placeholder="Here can be your description">
                      </textarea>
                      {errors.description && <div class="invalid-feedback">{errors.description.message}</div>}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-label">Statut</div>
                    <label className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" {...register('statut')}/>
                      <span className="form-check-label">Article est disponible pour la vente</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="card-footer text-end">
                <div className="d-flex">
                  <Link to="/articles/list" className="btn btn-link">Annuler</Link>
                  <button type="submit" className="btn btn-primary ms-auto">Valider</button>
                </div>
              </div>
            </form>

            </div>
          </div>
        </div>

        <AddCategory/>
    </div>
  )
}

export default AddArticle