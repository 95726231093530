import React from 'react'
import { Link } from 'react-router-dom'

function UnauthorizedPage() {
  return (
    <div className="border-top-wide border-primary d-flex flex-column">
        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="empty">
              <div className="empty-header">403</div>
              <p className="empty-title">Oops… Accès Refusé</p>
              <p className="empty-subtitle text-muted">
                Vous n'avez pas la permission d'accéder à cette page.
              </p>
              <div className="empty-action">
                <Link to="/dashboard" className="btn btn-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l6 6" /><path d="M5 12l6 -6" /></svg>
                  Retour
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default UnauthorizedPage