import { useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../../../utils/services/api/userApi"
import { user } from "../../../../redux/selectors";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { userSchema } from "../../../../utils/schemas/userSchema";

function Profil() {

    const currentUser = useSelector(user);

    const [udpateUser] = useUpdateUserMutation();

    const {handleSubmit, register, formState: {error}} = useForm({
        resolver: zodResolver(userSchema)
    })

  return (
    <div className='card-body'>

        <div>
            <h2 className="mb-4">Mon Profil</h2>
            <div className="row align-items-center">
                <div className="col-auto"><span className="avatar avatar-xl" style={{backgroundImage: `url(${currentUser?.imageUrl})`}}></span>
                </div>
                <div className="col-auto"><button className="btn">
                    Changer avatar
                </button></div>
                <div className="col-auto"><button className="btn btn-ghost-danger">
                    Supprimer avatar
                </button></div>
            </div>
            <h3 className="card-title mt-4">Details</h3>
            <div className="row g-3">
                <div className="col-md">
                    <div className="form-label">Nom</div>
                    <input type="text" className="form-control" value="Tabler"/>
                </div>
                <div className="col-md">
                    <div className="form-label">Prenom</div>
                    <input type="text" className="form-control" value="560afc32"/>
                </div>
                <div className="col-md">
                    <div className="form-label">Telephone</div>
                    <input type="text" className="form-control" value="Peimei, China"/>
                </div>
            </div>
            <div className="row g-3 mt-1">
                <div className="col-md">
                    <div className="form-label">Email</div>
                    <input type="number" className="form-control" value="(+509)"/>
                </div>
            </div>
            <h3 className="card-title mt-4">Pseudo</h3>
            <p className="card-subtitle">Votre pseudo sera montré publiquement aux autres, alors choisissez-le avec soin.</p>
            <div>
                <div className="row g-2">
                    <div className="col-auto">
                        <input type="text" className="form-control w-auto" value="paweluna@howstuffworks.com"/>
                    </div>
                </div>
            </div>
            <h3 className="card-title mt-4">Mot de passe</h3>
            <p className="card-subtitle">Pour votre mot de passe, utilisez un mélange de lettres, de chiffres et de caractères spéciaux.</p>
            <div className="row g-3">
                <div className="col-md">
                    <div className="form-label">Mot de passe actuel</div>
                    <input type="text" className="form-control" value="Tabler"/>
                </div>
                <div className="col-md">
                    <div className="form-label">Nouveau mot de passe</div>
                    <input type="text" className="form-control" value="560afc32"/>
                </div>
                <div className="col-md">
                    <div className="form-label">Confirme mot de passe</div>
                    <input type="text" className="form-control" value="Peimei, China"/>
                </div>
            </div>
            <h3 className="card-title mt-4">Public profile</h3>
            <p className="card-subtitle">Making your profile public means that anyone on the Dashkit network will be able to find
                you.</p>
            <div>
                <label className="form-check form-switch form-switch-lg">
                <input className="form-check-input" type="checkbox" />
                <span className="form-check-label form-check-label-on">You're currently visible</span>
                <span className="form-check-label form-check-label-off">You're
                    currently invisible</span>
                </label>
            </div>

            <div className="card-footer bg-transparent mt-auto">
                <div className="btn-list justify-content-end">
                <button className="btn">Cancel</button>
                <button className="btn btn-primary">Submit</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profil