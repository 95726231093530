import React, { useContext, useState } from 'react';
import { useCreateCategoryMutation } from '../../../../utils/services/api/categoryApi'
import { ToastContext } from '../../../../utils/contexts/context'

function AddCategory() {

  const [toast, setToast] = useContext(ToastContext);

  const [createCategory] = useCreateCategoryMutation();

  const [category, setCategory] = useState({
    nomCategorie: '',
    description: '',
    imageUrl: ''
  })

  const [isSuccess, setIsSuccess] = useState(false);

  // Methode pour upload l'image
  const handleImage=(e)=>{
    setCategory({...category, imageUrl: e.target.files[0]});
  }

  const onSubmit = (e) => {
    //  closeModal()
    e.preventDefault();
    let categorieData = new FormData()
    categorieData.append('nomCategorie',category.nomCategorie);
    categorieData.append('description',category.description);
    categorieData.append('imageUrl',category.imageUrl);
    createCategory(categorieData)
    .then((resp)=>{
      if(resp.data.message){
          setToast({
              ...toast,
              isOpen: true,
              severity: 'success',
              message: resp.data.message
            })

            setIsSuccess(true);

            setCategory({
              nomCategorie: '',
              description: '',
              imageUrl: ''
            });

      }else{
          setToast({
              ...toast,
              isOpen: true,
              severity: 'danger',
              message: resp.data.errors[0].message
            })
      }
    }).catch((error)=>
        console.log(error)
    )

  }
  
              
  return (
    <div className="modal modal-blur fade" id="modal-addCategory" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Ajouter Categorie</h5>
              <button type="button" className="btn-close" 
              data-bs-dismiss="modal"
              ></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row mb-3 align-items-end">
                  <div className="col-auto">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleImage} accept=".png, .jpg, .jpeg"
                  />
                    {category.imageUrl !== '' ?
                    <button className="avatar avatar-upload rounded" style={{backgroundColor: 'green', color: 'white'}} onClick={(e) =>  {e.preventDefault(); document.getElementById('fileInput').click()}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                      <span className="avatar-upload-text">upload</span>
                    </button> :
                    <button className="avatar avatar-upload rounded" onClick={(e) =>  {e.preventDefault(); document.getElementById('fileInput').click()}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                      <span className="avatar-upload-text">Icon</span>
                    </button>}
                  </div>
                  <div className="col">
                    <label className="form-label">Nom Categorie</label>
                    <input type="text" className="form-control" value={category.nomCategorie}
                      onChange={(e)=> setCategory({...category, nomCategorie: e.target.value})} required
                    />
                  </div>
                </div>
                <div>
                  <label className="form-label">Description</label>
                  <textarea rows="5" className="form-control" 
                    value={category.description} onChange={(e)=> setCategory({...category, description: e.target.value})}
                    placeholder="Here can be your description" required>
                  </textarea>
                </div>
              </div>
              <div className="modal-footer">
                {isSuccess && 
                <button type="button" className="btn btn-danger" 
                data-bs-dismiss="modal" onClick={()=> setIsSuccess(false)}
                >Close</button>}

                {!isSuccess &&
                <button type="submit" className="btn btn-primary" 
                onClick={(e)=> onSubmit(e)}>Add</button>}
              </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default AddCategory