import React, { useContext } from 'react'
import { useDeleteProductMutation } from '../../../../utils/services/api/productApi';
import { ToastContext } from '../../../../utils/contexts/context';

function DeleteArticle({ids, setIds}) {
  
  const [deleteProduct] = useDeleteProductMutation();

  const [toast, setToast] = useContext(ToastContext);
  
  const handleDelete = (ids) => {
    // Créer un tableau de promesses pour chaque suppression
    const deletePromises = ids.map(id => deleteProduct(id));

    // Utiliser Promise.all pour attendre que toutes les promesses soient résolues
    Promise.all(deletePromises)
        .then(responses => {
            // Vérifier toutes les réponses
            const success= responses.every(resp => resp.data.message);

            // On vide le tableau ids d'elements à supprimer
            setIds([]);

            if (success) {
              setToast({
                  ...toast,
                  isOpen: true,
                  severity: 'success',
                  message: 'Suppression réussie pour tous les produits'
              });
            } else {
                setToast({
                    ...toast,
                    isOpen: true,
                    severity: 'danger',
                    message: 'Echec de Suppression pour au moins un produit'
                });
            }
        })
        .catch(error => {
            console.error('Erreur lors de la suppression :', error);
            setToast({
                ...toast,
                isOpen: true,
                severity: 'danger',
                message: 'Erreur lors de la suppression des produits'
            });
        });
  }

  return (
    <div className="modal modal-blur fade" id="modal-danger" tabindex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-status bg-danger"></div>
          <div className="modal-body text-center py-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" /><path d="M12 9v4" /><path d="M12 17h.01" /></svg>
            <h3>Êtes-vous sure?</h3>
            <div className="text-muted">Voulez-vous vraiment supprimé ce fichier? Cette action est irréversible.</div>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <div className="row">
                <div className="col"><button className="btn w-100" data-bs-dismiss="modal">
                    Cancel
                  </button></div>
                <div className="col">
                  <button className="btn btn-danger w-100" data-bs-dismiss="modal"
                  onClick={()=> handleDelete(ids)}>
                    Delete {ids.length} items
                  </button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteArticle