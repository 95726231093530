import { createSlice } from "@reduxjs/toolkit";

export const suspendSlice = createSlice({
    name: 'suspend',
    initialState: [],
    reducers: {
        addSuspend : (currentState, action) => {
            currentState.push(action.payload);
        },
        cleanSuspend: () => {
            return []
        }
    }
})