import { useEffect, useState } from 'react';
import { useGetOneClientQuery } from '../../../../utils/services/api/clientApi';
import dayjs from 'dayjs';
import { generatePagination } from '../../../../utils';

function ViewCustomer({id}) {

    const {data: client} = useGetOneClientQuery(id);

    const [orders, setOrders] = useState([]);

    useEffect(()=> {
        if(typeof client?.orders !== 'undefined'){
            setOrders(client?.orders);
        }
    },[client])


    // Pour la pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(4);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = orders?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(orders?.length/recordsPerPage);
    const numbers = generatePagination(currentPage, npage);

  return (
    <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEnd" aria-labelledby="offcanvasEndLabel">
        <div className="offcanvas-header">
            <h2 className="offcanvas-title" id="offcanvasEndLabel">Client {client?.id}</h2>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div>
                <div className="card">
                    <div className="card-header">
                    <h4 className="card-title">Details: {client?.code}</h4>
                    </div>
                    <div className="list-group list-group-flush list-group-hoverable">
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate">
                                <div className="text-reset d-block">Nom Client</div>
                                <div className="d-block text-muted text-truncate mt-n1">{client?.nom} {client?.prenom}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset ">Email</div>
                            <div className=" text-muted text-truncate mt-n1">{client?.email}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Telephone</div>
                                <div className="text-muted text-truncate mt-n1">{client?.tel}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Adresse</div>
                                <div className="text-muted text-truncate mt-n1" style={{ whiteSpace: "wrap" }}>{client?.adresse}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Date d'enregistrement</div>
                                <div className="text-muted text-truncate mt-n1">{dayjs(client?.createdAt).format('DD-MM-YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="card" style={{marginTop: '20px'}}>
                    <div className="card-header">Liste Achats</div>
                    <div className="table-responsive">
                        <table className="table table-vcenter card-table">
                            <thead>
                            <tr>
                                <th>No Facture</th>
                                <th>Montant</th>
                                <th>Balance</th>
                                <th>Quantité</th>
                            </tr>
                            </thead>
                            <tbody>
                                {records?.map((order, i)=> (
                                    <tr key={i}>
                                        <td>{order.noFacture}</td>
                                        <td className="text-muted" >{(order.montantVendu + order.taxes)-order.discount}</td>
                                        <td className="text-muted" >{order.montantDue}</td>
                                        <td className="text-muted" >{order.items}x</td>
                                    </tr>
                                ))}

                                {orders?.length === 0 &&
                                    <tr>
                                        <td colspan={5} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer d-flex align-items-center">
                        <ul className="pagination m-0 ms-auto">
                            <li className="page-item">
                                <button onClick={prePage} className={`page-link ${currentPage===1 && 'disabled'}`} tabindex="-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                                </button>
                            </li>
                            {orders?.length!==0 &&
                            (numbers?.map((page, id)=> (
                                <li className={`page-item ${currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
                            )))}
                            <li className="page-item">
                                <button onClick={nextPage} className={`page-link ${currentPage===npage && 'disabled'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    if(!(id==='...')){
        setCurrentPage(id)
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }

}

export default ViewCustomer