import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import SalesChartBarSkeleton from '../../../../components/skeleton/SalesChartBarSkeleton';

function SalesChartBar({ isLoading, orders }) {

    const [chartData, setChartData] = useState({
        series: [
            { name: 'Total Vente', data: [] },
            { name: 'Total Revenue', data: [] },
            { name: 'Total Du', data: [] }
        ],
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                show: true
                },
                zoom: {
                enabled: false
                }
            },
            xaxis: {
                categories: []
            },
            colors: ['#0054a6', '#4299e1', '#2fb344'],
            plotOptions: {
                bar: {
                horizontal: false,
                columnWidth: '50%'
                }
            },
            legend: {
                show: true,
                fontSize: '12px',
                fontFamily: `'poppins', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                useSeriesColors: false
                },
                markers: {
                width: 16,
                height: 16,
                radius: 5
                },
                itemMargin: {
                horizontal: 10,
                vertical: 8
                }
            }
        }
     });

  useEffect(() => {
    if (!isLoading && orders && orders?.length > 0) {
      const formattedData = formatData(orders);
      setChartData(formattedData);
    }
  }, [isLoading, orders]);

  const formatData = (data) => {
    const months = [];
    const totalVente = [];
    const totalRevenu = [];
    const totalDu = [];

    const currentYear = new Date().getFullYear();

    const monthlyData = Array(12).fill(null).map((_, i) => ({
      month: new Date(currentYear, i, 1).toLocaleString('default', { month: 'short', year: 'numeric' }),
      total: 0,
      verse: 0,
      du: 0
    }));

    data.forEach(d => {
      const [year, month] = d.mois.split('-').map(Number);
      if (year === currentYear) {
        const monthIndex = month - 1;
        monthlyData[monthIndex].total = parseFloat(d.totalVente) || 0;
        monthlyData[monthIndex].verse = parseFloat(d.totalRevenu) || 0;
        monthlyData[monthIndex].du = parseFloat(d.totalDu) || 0;
      }
    });

    monthlyData.forEach(monthData => {
      months.push(monthData.month);
      totalVente.push(monthData.total);
      totalRevenu.push(monthData.verse);
      totalDu.push(monthData.du);
    });

    return {
      series: [
        { name: 'Total Vente', data: totalVente },
        { name: 'Total Revenue', data: totalRevenu },
        { name: 'Total Du', data: totalDu }
      ],
      options: {
        ...chartData.options,
        xaxis: {
          categories: months
        }
      }
    };
  };

  if (isLoading) {
    return( 
      <SalesChartBarSkeleton/>
    )}

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Vente Annuelles</h3>
        <div className="chart-lg" style={{height: '400px'}}>
          <Chart
            type='bar'
            options={chartData.options}
            series={chartData.series}
            height={chartData.height || '100%'}
          />
        </div>
      </div>
    </div>
  );
}

export default SalesChartBar;
