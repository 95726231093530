import React from 'react'

function FilterMagasin({filter, setFilter, data}) {
    
    const paysDoublons = data?.reduce((acc, item)=> 
        acc.includes(item.pays) ? acc : acc.concat(item.pays),
    [])

    const paysTrie = []
    const pays = paysDoublons?.filter((item)=>{
        if(!paysTrie.includes(item)){
        paysTrie.push(item)
        return true
        }
        return false
    })

    return (
        <div className="modal modal-blur fade" id="modal-team" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Filtre</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div class="mb-1">
                    <label className="form-label">Pays</label>
                    <select className="form-select" onChange={(e)=> setFilter({...filter, pays: e.target.value})}>
                        <option value="">All</option>
                        { 
                            pays?.map((pays, i)=> (
                                <option value={pays} key={i}>{pays}</option>
                            ))
                        }
                    </select>
                </div>
                <div class="mb-1">
                    <label className="form-label">Statut</label>
                    <select className="form-select" onChange={(e)=> setFilter({...filter, statut: e.target.value})}>
                        <option value="">All</option>
                        <option value={true}>Ouvert</option>
                        <option value={false}>Fermé</option>
                    </select>
                </div>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn me-auto" data-bs-dismiss="modal">Close</button>
                {/* <button type="button" className="btn btn-primary" data-bs-dismiss="modal">filter</button> */}
            </div>
            </div>
        </div>
        </div>
    )
}

export default FilterMagasin