import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../../environments/environments"; 

const url = env;

const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        
        getUsers : builder.query({
            query: (filter) => ({
                url: '/auth/users',
                method: 'GET',
                params: filter
            }),
            providesTags: ['User']
        }),

        getOneUser: builder.query({
            query: (id) => ({
                url: `/auth/user/${id}`,
                method: 'GET'
            }),
            providesTags: ['User']
        }),

        createConnection : builder.mutation({
            query: (credential) => ({
                url: '/auth/login',
                method: 'POST',
                body: credential
            }),
            invalidatesTags: ['User']
        }),

        createUser: builder.mutation({
            query: (user) => ({
                url: '/auth/signup',
                method: 'POST',
                body: user
            }),
            invalidatesTags: ['User']
        }),

        updateUser: builder.mutation({
            query: ({id, user}) =>({
                url: `/auth/edit/${id}`,
                method: 'PUT',
                body: user
            }),
            invalidatesTags: ['User']
        }),

        deleteUser : builder.mutation({
            query: (id) => ({
                url: `/auth/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['User']
        }),

        getReportUser: builder.query({
            query: (filter) => ({
                url: '/auth/sales/reports/users',
                method: 'GET',
                params: filter
            }),
            providesTags: ['User']
        })
    })
})

export const {
    useGetUsersQuery,
    useGetOneUserQuery,
    useCreateConnectionMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetReportUserQuery
} = userApi;

export default userApi;