import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/header-page/Header';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContext } from '../../../../utils/contexts/context';
import { useCreateRoleMutation } from '../../../../utils/services/api/roleApi';

function AddRole() {
  
  const [toast, setToast] = useContext(ToastContext);
    // const id_params = useParams();

    const navigate = useNavigate();

    const [nomRole, setNomRole]= useState('')

    const [allPermissions, setAllPermissions]= useState(false)
  
    const [permissionArticle, setPermissionArticle] = useState({
        menu: 'articles',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionMagasin, setPermissionMagasin] = useState({
      menu: 'magasins',
      haveView: false,
      haveAdd: false,
      haveEdit: false,
      haveDelete: false
  })

    const [permissionOrder, setPermissionOrder] = useState({
        menu: 'ventes',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionPayment, setPermissionPayment] = useState({
      menu: 'paiements',
      haveView: false,
      haveAdd: false,
      haveEdit: false,
      haveDelete: false
  })

    const [permissionPos, setPermissionPos] = useState({
        menu: 'pos',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionTransfert, setPermissionTransfert] = useState({
      menu: 'transferts',
      haveView: false,
      haveAdd: false,
      haveEdit: false,
      haveDelete: false
    })

    const [permissionAjuster, setPermissionAjuster] = useState({
      menu: 'ajuster',
      haveView: false,
      haveAdd: false,
      haveEdit: false,
      haveDelete: false
    })

    const [permissionClient, setPermissionClient] = useState({
      menu: 'clients',
      haveView: false,
      haveAdd: false,
      haveEdit: false,
      haveDelete: false
  })

    const [permissionUser, setPermissionUser] = useState({
        menu: 'utilisateurs',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    const [permissionRole, setPermissionRole] = useState({
      menu: 'roles',
      haveView: false,
      haveAdd: false,
      haveEdit: false,
      haveDelete: false
  })

    const [permissionRapport, setPermissionRapport] = useState({
        menu: 'rapports',
        haveView: false,
        haveAdd: false,
        haveEdit: false,
        haveDelete: false
    })

    
    const updatePermissions = ()=>{
        if(allPermissions){
            setPermissionArticle({...permissionArticle, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionMagasin({...permissionMagasin, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionOrder({...permissionOrder, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionPayment({...permissionPayment, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionPos({...permissionPos, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionTransfert({...permissionTransfert, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionAjuster({...permissionAjuster, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true})
            setPermissionClient({...permissionClient, haveView: true, haveAdd: true, haveEdit:  true, haveDelete: true})
            setPermissionUser({...permissionUser, haveView: true, haveAdd: true, haveEdit:  true, haveDelete: true})
            setPermissionRole({...permissionRole, haveView: true, haveAdd: true, haveEdit:  true, haveDelete: true})
            setPermissionRapport({...permissionRapport, haveView: true, haveAdd: true, haveEdit: true, haveDelete: true}) 
        }else{
            setPermissionArticle({...permissionArticle, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionMagasin({...permissionMagasin, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionOrder({...permissionOrder, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionPayment({...permissionPayment, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionPos({...permissionPos, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionTransfert({...permissionTransfert, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionAjuster({...permissionAjuster, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})
            setPermissionClient({...permissionClient, haveView: false, haveAdd: false, haveEdit:  false, haveDelete: false})
            setPermissionUser({...permissionUser, haveView: false, haveAdd: false, haveEdit:  false, haveDelete: false})
            setPermissionRole({...permissionRole, haveView: false, haveAdd: false, haveEdit:  false, haveDelete: false})
            setPermissionRapport({...permissionRapport, haveView: false, haveAdd: false, haveEdit: false, haveDelete: false})  
        }
    }
    
    useEffect(()=>{
        updatePermissions();
    },[allPermissions])

  
    const [createRole] = useCreateRoleMutation();

    const handleSubmit = (e) => {
      e.preventDefault()

        let data = {
            'nom': nomRole,
            'privileges': [
                permissionArticle, permissionMagasin, permissionOrder, permissionPayment, permissionPos,
                permissionTransfert, permissionAjuster,permissionClient, permissionUser, 
                permissionRole, permissionRapport
            ]
        }

        createRole(data)
        .then((resp)=>{
          if(resp.data.message){
            setToast({
              ...toast,
              isOpen: true,
              severity: 'success',
              message: resp.data.message
            })

            navigate('/utilisateurs/roles/list')
          }else{
            setToast({
              ...toast,
              isOpen: true,
              severity: 'danger',
              message: resp.data.errors[0].message
            })
          }
        }).catch((error)=> {
          console.log(error)
        })
    }

  return (
    <div className="page-wrapper">
      <Header title={"Ajouter Role"}/>

      <div className="page-body">
          <div className="container-xl">
            <div className="card">

              <div className="card-header">
                <h3 className="card-title">Rôle employés</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="row row-cards">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Nom role</label>
                        <input type="text" className="form-control" placeholder="Admin" value={nomRole}
                          onChange={(e)=>setNomRole(e.target.value)} required/>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="mb-3">
                        <label className="form-label">Permissions: </label>
                      </div>
                    </div>

                    {/* Admin */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Droit Administrateurs
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-10">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" checked={allPermissions} 
                            onChange={(e)=> setAllPermissions(e.target.checked)}/>
                            <span class="form-check-label">Toutes les droits</span>
                          </label>
                      </div>
                    </div>
                    
                    {/* Gestion Article */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Articles
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox"
                            checked={permissionArticle.haveView} 
                            onChange={(e)=> setPermissionArticle({...permissionArticle, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox"
                            checked={permissionArticle.haveAdd} 
                            onChange={(e)=> setPermissionArticle({...permissionArticle, haveAdd: e.target.checked})} 
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionArticle.haveEdit} 
                              onChange={(e)=> setPermissionArticle({...permissionArticle, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionArticle.haveDelete} 
                              onChange={(e)=> setPermissionArticle({...permissionArticle, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion Magasin */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Magasins
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox"
                            checked={permissionMagasin.haveView} 
                            onChange={(e)=> setPermissionMagasin({...permissionMagasin, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox"
                            checked={permissionMagasin.haveAdd} 
                            onChange={(e)=> setPermissionMagasin({...permissionMagasin, haveAdd: e.target.checked})} 
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionMagasin.haveEdit} 
                              onChange={(e)=> setPermissionMagasin({...permissionMagasin, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionMagasin.haveDelete} 
                              onChange={(e)=> setPermissionMagasin({...permissionMagasin, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion Ventes */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Ventes
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionOrder.haveView} 
                            onChange={(e)=> setPermissionOrder({...permissionOrder, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionOrder.haveAdd} 
                            onChange={(e)=> setPermissionOrder({...permissionOrder, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionOrder.haveEdit} 
                              onChange={(e)=> setPermissionOrder({...permissionOrder, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionOrder.haveDelete} 
                              onChange={(e)=> setPermissionOrder({...permissionOrder, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion des paiements */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Paiements
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionPayment.haveView} 
                            onChange={(e)=> setPermissionPayment({...permissionPayment, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionPayment.haveAdd} 
                            onChange={(e)=> setPermissionPayment({...permissionPayment, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionPayment.haveEdit} 
                              onChange={(e)=> setPermissionPayment({...permissionPayment, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionPayment.haveDelete} 
                              onChange={(e)=> setPermissionPayment({...permissionPayment, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion POS */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion du Point de vente
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionPos.haveView} 
                            onChange={(e)=> setPermissionPos({...permissionPos, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionPos.haveAdd} 
                            onChange={(e)=> setPermissionPos({...permissionPos, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionPos.haveEdit} 
                              onChange={(e)=> setPermissionPos({...permissionPos, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionPos.haveDelete} 
                              onChange={(e)=> setPermissionPos({...permissionPos, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion des transferts */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des transferts
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionTransfert.haveView} 
                            onChange={(e)=> setPermissionTransfert({...permissionTransfert, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionTransfert.haveAdd} 
                            onChange={(e)=> setPermissionTransfert({...permissionTransfert, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionTransfert.haveEdit} 
                              onChange={(e)=> setPermissionTransfert({...permissionTransfert, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionTransfert.haveDelete} 
                              onChange={(e)=> setPermissionTransfert({...permissionTransfert, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion des ajustements */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Ajustements
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionAjuster.haveView} 
                            onChange={(e)=> setPermissionAjuster({...permissionAjuster, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionAjuster.haveAdd} 
                            onChange={(e)=> setPermissionAjuster({...permissionAjuster, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionAjuster.haveEdit} 
                              onChange={(e)=> setPermissionAjuster({...permissionAjuster, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionAjuster.haveDelete} 
                              onChange={(e)=> setPermissionAjuster({...permissionAjuster, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion Client */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Clients
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionClient.haveView} 
                            onChange={(e)=> setPermissionClient({...permissionClient, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionClient.haveAdd} 
                            onChange={(e)=> setPermissionClient({...permissionClient, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionClient.haveEdit} 
                              onChange={(e)=> setPermissionClient({...permissionClient, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionClient.haveDelete} 
                              onChange={(e)=> setPermissionClient({...permissionClient, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion Utilisateurs */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Utilisateurs
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionUser.haveView} 
                            onChange={(e)=> setPermissionUser({...permissionUser, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionUser.haveAdd} 
                            onChange={(e)=> setPermissionUser({...permissionUser, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionUser.haveEdit} 
                              onChange={(e)=> setPermissionUser({...permissionUser, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionUser.haveDelete} 
                              onChange={(e)=> setPermissionUser({...permissionUser, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion Roles */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Roles
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionRole.haveView} 
                            onChange={(e)=> setPermissionRole({...permissionRole, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionRole.haveAdd} 
                            onChange={(e)=> setPermissionRole({...permissionRole, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionRole.haveEdit} 
                              onChange={(e)=> setPermissionRole({...permissionRole, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionRole.haveDelete} 
                              onChange={(e)=> setPermissionRole({...permissionRole, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>

                    {/* Gestion Rapports */}
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        Gestion des Rapports
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionRapport.haveView} 
                            onChange={(e)=> setPermissionRapport({...permissionRapport, haveView: e.target.checked})}
                            />
                            <span class="form-check-label">Lire</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                          <label class="form-check">
                            <input class="form-check-input" type="checkbox" 
                            checked={permissionRapport.haveAdd} 
                            onChange={(e)=> setPermissionRapport({...permissionRapport, haveAdd: e.target.checked})}
                            />
                            <span class="form-check-label">Ajouter</span>
                          </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionRapport.haveEdit} 
                              onChange={(e)=> setPermissionRapport({...permissionRapport, haveEdit: e.target.checked})}
                              />
                              <span class="form-check-label">Modifier</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="mb-3">
                        <div className="mb-3">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" 
                              checked={permissionRapport.haveDelete} 
                              onChange={(e)=> setPermissionRapport({...permissionRapport, haveDelete: e.target.checked})}
                              />
                              <span class="form-check-label">Supprimer</span>
                            </label>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>

                <div className="card-footer text-end">
                  <div className="d-flex">
                    <Link to="/utilisateurs/roles/list" className="btn btn-link">Annuler</Link>
                    <button type="submit" className="btn btn-primary ms-auto">Valider</button>
                  </div>
                </div>
            </form>

            </div>
          </div>
      </div>

    </div>
  )
}

export default AddRole