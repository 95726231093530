import { z } from 'zod';

export const magasinSchema = z.object({
    nomStore: z.string().min(1, { message: 'Champs Obligatoire' }),
    etat: z.string().min(1, { message: 'Champs Obligatoire' }),
    ville: z.string().min(1, { message: 'Champs Obligatoire' }),
    codePostal: z.string().min(1, { message: 'Champs Obligatoire' }),
    repere: z.string().min(1, { message: 'Champs Obligatoire' }),
    pays: z.string().min(1, { message: 'Champs Obligatoire' }),
    tel: z.number({required_error: 'Champs Obligatoire', invalid_type_error: 'Chiffres Uniquement'}).gte(7, { message: 'Minimum 7 chiffres' }).positive(),
    email: z.string({ required_error: 'Champs Obligatoire' }).email({ message: "Format d'email incorrect" }),
    statut: z.boolean()
})