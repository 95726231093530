import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../../environments/environments";

const url = env;

const paymentApi = createApi({
    reducerPath: 'paymentApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['payment'],
    endpoints: (builder) => ({
        getPayments : builder.query({
            query: (filter) => ({
                url: '/payments',
                method: 'GET',
                params: filter
            }),
            providesTags: ['payment']
        }),

        createPayment : builder.mutation({
            query: (payment) => ({
                url: '/payments/create',
                method: 'POST',
                body: payment
            }),
            invalidatesTags: ['payment']
        }),

        deletePayment : builder.mutation({
            query: (id) =>({
                url: `/payments/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['payment']
        })
    })
})

export const {
    useGetPaymentsQuery,
    useCreatePaymentMutation,
    useDeletePaymentMutation
} = paymentApi;

export default paymentApi;