import React from 'react'

function BestProductSkeleton() {
  return (
    <div className="card placeholder-glow">
        <div className="card-header">
            <div className='card-title placeholder placeholder-xs col-9'></div>
        </div>
        <div>
            <ul className="list-group list-group-flush">
                {[1,2,3,4].map((i)=> (
                    <li className="list-group-item" key={i}>
                        <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="avatar placeholder"></div>
                        </div>
                        <div className="col-7">
                            <div className="placeholder placeholder-xs col-9"></div>
                            <div className="placeholder placeholder-xs col-7"></div>
                        </div>
                        <div className="col-2 ms-auto text-end">
                            <div className="placeholder placeholder-xs col-8"></div>
                            <div className="placeholder placeholder-xs col-10"></div>
                        </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
  )
}

export default BestProductSkeleton