import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { categoryApi, clientApi, magasinsApi, orderApi, paymentApi, productApi, roleApi, userApi } from "../utils/services/api";
import { cartSlice } from "./slices/CartSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { draftSlice } from "./slices/DraftSlice";
import { suspendSlice } from "./slices/SuspendSlice";
import { authSlice } from "./slices/authSlice";
// import authMiddleWare from "./middlewares/authMiddleware";

let state = {}

const rootReducer = combineReducers({
    cart: cartSlice.reducer,
    draft: draftSlice.reducer,
    suspend: suspendSlice.reducer,
    auth: authSlice.reducer,
    [magasinsApi.reducerPath] : magasinsApi.reducer,
    [productApi.reducerPath] : productApi.reducer,
    [categoryApi.reducerPath] : categoryApi.reducer,
    [orderApi.reducerPath] : orderApi.reducer,
    [paymentApi.reducerPath] : paymentApi.reducer,
    [clientApi.reducerPath] : clientApi.reducer,
    [userApi.reducerPath] : userApi.reducer,
    [roleApi.reducerPath] : roleApi.reducer
})

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        magasinsApi.reducerPath,
        productApi.reducerPath,
        categoryApi.reducerPath, 
        orderApi.reducerPath, 
        paymentApi.reducerPath, 
        clientApi.reducerPath, 
        userApi.reducerPath, 
        roleApi.reducerPath
    ]
}

const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
)

const store = configureStore({
    preloadedState: state,
    reducer: persistedReducer,
    extraReducer: null,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}).concat(
            magasinsApi.middleware,
            productApi.middleware,
            categoryApi.middleware,
            orderApi.middleware,
            paymentApi.middleware,
            clientApi.middleware,
            userApi.middleware,
            roleApi.middleware,
            // authMiddleWare
        ).concat(thunk)
})

const persistor = persistStore(store)

export {store, persistor};