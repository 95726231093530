import React from 'react'

function SalesChartBarSkeleton() {
  return (
    <div className="card placeholder-glow">
        <div className="card-body">
        <div className="card-title placeholder placeholder-xs col-9"></div>
        <div className="chart-lg" style={{height: '300px'}}>
            <div className='ratio ratio-21x9 card-img-top placeholder'></div>
        </div>
        </div>
    </div>
  )
}

export default SalesChartBarSkeleton