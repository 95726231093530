import PosLayout from "../layout/posLayout";
import Error from "../pages/errors/not-found/error-404";
import Pos from "../pages/pos/Pos";
import ProtectedRoute from "./ProtectedRoute";

export const PosRoutes = {
    path: '/',
    element: <ProtectedRoute
        element={<PosLayout/>}
        routePath='pos'
        privilegeType= 'haveView'
    />,
    errorElement: <Error/>,
    children: [
        {
            path: '/orders/pos',
            element: <Pos/>
        }
    ]
}