import { NavLink, useLocation } from "react-router-dom";
import Profil from "../header/headerComponents/Profil";
import Notification from "../header/headerComponents/Notification";
import { useSelector } from "react-redux";
import { privileges } from "../../../redux/selectors";

function Menu(){

  const schweiz= { fontFamily : 'CustomFont, schweiz, Poppins', fontWeight: 'normal'}
  const location = useLocation();
  const path = location.pathname;
    
  const handleIsActive = (etat) =>{
    let style = ''
    if(etat){
      style="active"
    }else{
      style=""
    }
    return style;
  }

  const comparePath = (value)=>{
    let paths = path.split('/');
    if(value === paths[1])
      return true;
    else
      return false;
  }

  // Pour gerer les droit d'accès
  const CurrentPrivileges = useSelector(privileges);
  const hasPrivilege = (routePath, privilegeType) => {
    const privilege = CurrentPrivileges.find(p => p.menu === routePath);
    const hasPrivilege = privilege ? privilege[privilegeType] : false;
    return hasPrivilege;
  };

    return (
      <aside className="navbar navbar-vertical navbar-expand-lg" data-bs-theme="dark">
      
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark mt-2" style={schweiz}>
            BelOrest
          </h1>
          <div className="navbar-nav flex-row d-lg-none">
            <div className="d-none d-lg-flex">
              <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip"
		   data-bs-placement="bottom">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
              </a>
              <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip"
		   data-bs-placement="bottom">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
              </a>
              <div className="nav-item dropdown d-none d-md-flex me-3">
                <NavLink to="#" className="nav-link px-0" data-bs-toggle="dropdown" tabindex="-1" aria-label="Show notifications">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                  <span className="badge bg-red"></span>
                </NavLink>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                  <Notification/>
                </div>
              </div>
            </div>
            <div className="nav-item dropdown">
              <Profil/>
            </div>
          </div>
          {/* Menu */}
          <div className="collapse navbar-collapse" id="sidebar-menu">
            <ul className="navbar-nav pt-lg-3">
                
              <li className="nav-item">
                <NavLink className={({isActive})=> `nav-link ${handleIsActive(isActive)}`} to="/dashboard" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M4 13m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M14 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M14 15m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Dashboard
                  </span>
                </NavLink>
              </li>

              <li className={comparePath('magasins') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-current-location"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M12 12m-8 0a8 8 0 1 0 16 0a8 8 0 1 0 -16 0" /><path d="M12 2l0 2" /><path d="M12 20l0 2" /><path d="M20 12l2 0" /><path d="M2 12l2 0" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Magasins
                  </span>
                </NavLink>
                <div className={comparePath('magasins') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('magasins', 'haveView')&&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/magasins/list">
                        Liste Magasins
                      </NavLink>}
                      {hasPrivilege('magasins', 'haveAdd')&&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/magasins/ajouter">
                        Ajouter Magasin
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li>

              {/* Articles */}
              <li className={comparePath('articles') ? "nav-item dropdown active" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-basket"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z" /><path d="M17 10l-2 -6" /><path d="M7 10l2 -6" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Articles
                  </span>
                </NavLink>
                <div className={comparePath('articles') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('articles', 'haveView')&&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/articles/list">
                        Liste Articles
                      </NavLink>}
                      {hasPrivilege('articles', 'haveAdd') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/articles/ajouter">
                        Ajouter Article
                      </NavLink>}
                      {hasPrivilege('articles', 'haveAdd')&&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/articles/etiquette">
                        Générer Étiquettes
                      </NavLink>}
                      {hasPrivilege('articles', 'haveView')&&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/articles/categories/list">
                        Lister Categories
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li>
              
              {/* Orders */}
              <li className={comparePath('orders') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-shopping-cart"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17h-11v-14h-2" /><path d="M6 5l14 1l-1 7h-13" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Ventes
                  </span>
                </NavLink>
                <div className={comparePath('orders') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('ventes', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/orders/list">
                        Liste Ventes
                      </NavLink>}
                      {hasPrivilege('pos', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/orders/pos">
                        Point de vente
                      </NavLink>}
                      {hasPrivilege('paiements', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/orders/payments">
                        Liste Paiements
                      </NavLink>}
                      {hasPrivilege('paiements', 'haveAdd') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/orders/payments/ajouter">
                        Effectuer Paiements
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li>

              {/* Transfert de produit */}
              {/* <li className={comparePath('transferts') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-truck-delivery"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" /><path d="M3 9l4 0" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Transferts d'actions
                  </span>
                </NavLink>
                <div className={comparePath('transferts') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('transferts', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/transferts/list">
                        Liste des Transferts
                      </NavLink>}
                      {hasPrivilege('transferts', 'haveAdd') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/transferts/ajouter">
                        Ajouter un Transfert
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li> */}

              {/* Ajustement des stocks */}
              {/* <li className={comparePath('ajustements') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-stack-back"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 8l8 4l8 -4l-8 -4z" /><path d="M12 16l-4 -2l-4 2l8 4l8 -4l-4 -2l-4 2z" fill="currentColor" /><path d="M8 10l-4 2l4 2m8 0l4 -2l-4 -2" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Ajustement Stocks
                  </span>
                </NavLink>
                <div className={comparePath('ajustements') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('ajuster', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/ajustements/list">
                        Liste Ajustements
                      </NavLink>}
                      {hasPrivilege('ajuster', 'haveAdd') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/ajustements/ajouter">
                        Ajuster Stock 
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li> */}

              {/* Clients */}
              <li className={comparePath('clients') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-address-book"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" /><path d="M10 16h6" /><path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 8h3" /><path d="M4 12h3" /><path d="M4 16h3" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Clients
                  </span>
                </NavLink>
                <div className={comparePath('clients') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('clients', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/clients/list">
                        Liste Clients
                      </NavLink>}
                      {hasPrivilege('clients', 'haveAdd') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/clients/ajouter">
                        Ajouter Clients
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li>

              {/* Utilisateurs */}
              <li className={comparePath('utilisateurs') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-users"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Gestion Utilisateurs
                  </span>
                </NavLink>
                <div className={comparePath('utilisateurs') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      {hasPrivilege('utilisateurs', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/utilisateurs/list">
                        Liste Utilisateurs
                      </NavLink>}
                      {hasPrivilege('utilisateurs', 'haveAdd') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/utilisateurs/ajouter">
                        Ajouter Utilisateurs
                      </NavLink>}
                      {hasPrivilege('roles', 'haveView') &&
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/utilisateurs/roles/list">
                        Lister Roles
                      </NavLink>}
                    </div>
                  </div>
                </div>
              </li>

              {/* Rapports */}
              {hasPrivilege('rapports', 'haveView') &&
              <li className={comparePath('rapports') ? "nav-item active dropdown" : "nav-item dropdown"}>
                <NavLink className="nav-link dropdown-toggle" to="#navbar-layout" data-bs-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chart-pie"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8" /><path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Rapports
                  </span>
                </NavLink>
                <div className={comparePath('rapports') ? "dropdown-menu show" : "dropdown-menu"}>
                  <div className="dropdown-menu-columns">
                    <div className="dropdown-menu-column">
                      <NavLink className="dropdown-item" to="/rapports/journalier">
                        Journalier
                      </NavLink>
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/rapports/categories">
                        Categories
                      </NavLink>
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/rapports/produits">
                        Produits
                      </NavLink>
                      <NavLink className={({isActive})=> `dropdown-item ${handleIsActive(isActive)}`} to="/rapports/clients">
                        Clients
                      </NavLink>
                    </div>
                  </div>
                </div>
              </li>}

              {/* Reglages */}
              {/* <li className="nav-item">
                <NavLink className={({isActive})=> `nav-link ${handleIsActive(isActive)}`} to="/reglages" >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-settings"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg>
                  </span>
                  <span className="nav-link-title">
                    Réglages
                  </span>
                </NavLink>
              </li> */}

            </ul>
          </div>
        </div>
      </aside>
)}
export default Menu