import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/header-page/Header'
import { Link, useNavigate } from 'react-router-dom'
import { randomNumberInRange } from '../../../../utils';
import dayjs from 'dayjs';
import { useGetOrderDueQuery } from '../../../../utils/services/api/orderApi';
import { useDebouncedCallback } from 'use-debounce';
import { useCreatePaymentMutation } from '../../../../utils/services/api/paymentApi';
import { ToastContext } from '../../../../utils/contexts/context';

function AddPaiement() {

  const navigate = useNavigate();

  // Date vente 
  const today= new Date();
  const date=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);
  
  const [toast, setToast] = useContext(ToastContext);

  const [paiement, setPaiement] = useState({
    codePayment: `PAY-${randomNumberInRange(1,100)}${randomNumberInRange(100,1000)}`,
    amount: 0,
    methodePaiement: 'cash',
    orderId: 0,
    datePayment: date,
    notePayment: ''
  })

  const [filter, setFilter] = useState({
    query: ''
  })

  const handleSearch = useDebouncedCallback((e) =>{
    e.preventDefault();
    setFilter({...filter, query: e.target.value})
  }, 100);

  // Variable pour visualiser le montantDue de la commande choisie
  const [balance, setBalance] = useState(0)

  const handleCheck = (data)=>{
    if(data===0){
      setPaiement({...paiement, orderId: 0});
      setBalance(0);
    }else{
      setPaiement({...paiement, orderId: data.id});
      setBalance(data.montantDue);
    }
  }

  useEffect(()=> {
    setPaiement({...paiement, orderId: 0});
    setBalance(0);
  }, [filter])

  const renduMonnaie = () =>{
    let monnaie=0;
    let versement = parseFloat(paiement.amount);
    if(balance < versement){
        monnaie = versement-balance;
    }
    return monnaie;
  }

  const {data: orders, isLoading} = useGetOrderDueQuery(filter);

  const [createPayment] = useCreatePaymentMutation();

  const handlePay = () => {
    let amount = paiement.amount-renduMonnaie();
    setPaiement({...paiement, amount: amount});

    createPayment(paiement)
    .then((resp)=> {
      if(resp.data.message){ 
        setToast({
          ...toast,
          isOpen: true,
          severity: 'success',
          message: resp.data.message
        })
        navigate('/orders/payments');
      }else{
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.errors[0].message
        })
      }
    }).catch((error)=> {
      console.log(error)
    })
  }

  return (
    <div className="page-wrapper">
        <Header title={"Effectuer Paiement"}/>
        <div className="page-body">
          <div className="container-xl">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Effectuer paiement</h3>
              </div>

              <div className="card-body">
                <div className="row row-cards">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Ref Paiement</label>
                      <input type="text" className="form-control" value={paiement.codePayment} placeholder="Reference" disabled/>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Date Paiement</label>
                      <input type="text" className='form-control' value={date} placeholder="DD/MM/YYYY" disabled/>
                    </div>
                  </div>
                  <div className="col-sm-12 row" style={{ padding: '15px', background: '#ececec'}}>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Methode de Paiement</label>
                        <select className='form-control form-select' value={paiement.methodePaiement} onChange={(e)=> setPaiement({...paiement, methodePaiement: e.target.value})}>
                            <option value="cash">Cash</option>
                            <option value="creditCard">Carte de credit</option>
                            <option value="none">Credit</option>
                          </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Dette</label>
                        <input type="number" className='form-control' value={balance} placeholder="Montant à verser" disabled/>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Montant Versé</label>
                        <input type="number" className='form-control' value={paiement.amount} onChange={(e)=> setPaiement({...paiement, amount: e.target.value})} placeholder="Montant Reçu" required/>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Monnaie</label>
                        <input type="number" className='form-control' value={renduMonnaie()} placeholder="Monnaie"/>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="mb-3">
                      <label className="form-label">Reference Vente</label>
                      <input type="text" className='form-control' onChange={handleSearch} placeholder="Code vente"/>
                    </div>
                  </div>

                  {/* Tableau de commande */}
                  <div className="col-sm-12">
                    <div className="card mb-3">
                      <div className='card-header'>
                        <h5 className="card-title" style={{fontWeight: 500, fontSize: '1em'}}>Factures avec Balance</h5>
                      </div>
                      <div className="table-responsive">
                          <table className="table table-vcenter card-table">
                              <thead>
                              <tr>
                                  <th></th>
                                  <th>No Facture</th>
                                  <th>Balance</th>
                                  <th>Montant versé</th>
                                  <th>Client</th>
                                  <th>Contact</th>
                                  <th>Date vente</th>
                              </tr>
                              </thead>
                              <tbody>
                                  {isLoading &&
                                      <tr>
                                          <td colspan={7} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                                      </tr>
                                  }
                                  {orders?.map((order, i)=> (
                                      <tr key={i}>
                                        <td>
                                          <input className="form-check-input m-0 align-middle"
                                            checked={paiement.orderId === order.id} 
                                            onChange={(e) => handleCheck(e.target.checked ? order : 0)}
                                            type="checkbox" aria-label="Select all invoices"/>
                                        </td>
                                        <td className="text-muted">{order.noFacture}</td>
                                        <td className="text-muted">{order.montantDue}</td>
                                        <td className="text-muted" >{order.montantVerser}</td>
                                        <td className="text-muted" >{`${order.customer.nom} ${order.customer.prenom}`}</td>
                                        <td className="text-muted" >{order.customer.tel}</td>
                                        <td className="text-muted" >{dayjs(order.dateVente).format('DD-MM-YYYY . hh:mm a')}</td>
                                      </tr>
                                  ))}
                                  {orders?.length === 0 &&
                                        <tr>
                                          <td colspan={7} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                                        </tr>
                                  }
                              </tbody>
                          </table>
                      </div>
                    </div>
                  </div>

                  {/* Note de paiement */}
                  <div className="col-sm-12">
                    <div className="mb-3">
                      <label className="form-label">Note de Paiement</label>
                      <textarea rows="5" className='form-control' value={filter.notePayment} onChange={(e)=> setFilter({...filter, notePayment: e.target.value})} placeholder="Note de Paiement">
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="card-footer text-end">
                <div class="d-flex">
                  <Link to="/articles/list" class="btn btn-link">Annuler</Link>
                  <button onClick={()=> handlePay()} class="btn btn-primary ms-auto" disabled={paiement.orderId===0 ? true : false}>Valider</button>
                </div>
              </div>

            </div>
          </div>
        </div>
    </div>
  )
}

export default AddPaiement