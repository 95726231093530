import {Link, useRouteError} from 'react-router-dom';

function Error() {
    const error = useRouteError();
    
  return (
    <div className="border-top-wide border-primary d-flex flex-column">
        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="empty">
              <div className="empty-header">404</div>
              <p className="empty-title">Oops… You just found an error page</p>
              <p className="empty-subtitle text-muted">
                {error?.error.toString() ?? error?.toString()}
              </p>
              <div className="empty-action">
                <Link to="/dashboard" className="btn btn-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l6 6" /><path d="M5 12l6 -6" /></svg>
                  Retour
                </Link>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Error