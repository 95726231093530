import {Provider} from 'react-redux';
import { persistor, store } from './redux/store';
import { router } from './routes';
import {RouterProvider} from 'react-router-dom';
import { ToastProvider } from './utils/contexts/context';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastProvider>
          <RouterProvider router={router}/>
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
