import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { magasins } from '../../../../redux/selectors'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { authSlice } from '../../../../redux/slices/authSlice';
import { DateRange } from 'react-date-range';
import dayjs from 'dayjs';

function Header(props) {
    
    // Pour le composant select
    const animatedComponents = makeAnimated();
    
    const affectations = useSelector(magasins);
    
    const options = affectations?.map(store => ({
        value: store.id,
        label: store.nomStore + (!store.statut ? ' Fermé' : ''),
        isDisabled: !store.statut,
        statut: store.statut
    }))
    const [choix, setChoix] = useState(options.find((item)=> item.statut === true));

    const [current, setCurrent] = useState('');
    const dispatch = useDispatch()
    useEffect(()=> {
        setCurrent(choix.label)
        dispatch(authSlice.actions.currentStore({id: choix.value})) 
        props.setFilter({...props.filter, idStore: choix.value})
    }, [choix])

    // Date
    const [rangeDate, setRangeDate] = useState({
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
        key: 'selection'
    })

    const handleRangeDate = (ranges) => {
        setRangeDate(ranges.selection);
        props.setFilter({...props.filter, 
            startDate: dayjs(ranges.selection.startDate),
            endDate: dayjs(ranges.selection.endDate)
        })
    }

    const [open, setOpen] = useState(false)

  return (
    <div className="page-header d-print-none">
        <div className="container-xl">
        <div className="row g-2 align-items-center">
            <div className="col">
            <div className="page-pretitle">
                Dashboard
            </div>
            <h4 className="page-title">
                {current}
            </h4>
            </div>
            
            <div className="col-auto ms-auto d-print-none">
                <div className="btn-list">
                    <span className="d-none d-sm-inline">
                        <div className="mb-3">
                                <Select
                                    options={options}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isOptionDisabled={(option)=> option.isDisabled}
                                    onChange={(selected)=> setChoix(selected)}
                                    defaultValue={choix}
                                />
                        </div>
                    </span>

                    <span className="d-none d-sm-inline">
                        <div className='calendarWrap'>
                            <button className='btn btn-primary' style={{height: '38px'}} onClick={()=> setOpen(open => !open)}>
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  className="icon icon-tabler icons-tabler-filled icon-tabler-calendar"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16 2a1 1 0 0 1 .993 .883l.007 .117v1h1a3 3 0 0 1 2.995 2.824l.005 .176v12a3 3 0 0 1 -2.824 2.995l-.176 .005h-12a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-12a3 3 0 0 1 2.824 -2.995l.176 -.005h1v-1a1 1 0 0 1 1.993 -.117l.007 .117v1h6v-1a1 1 0 0 1 1 -1zm3 7h-14v9.625c0 .705 .386 1.286 .883 1.366l.117 .009h12c.513 0 .936 -.53 .993 -1.215l.007 -.16v-9.625z" /><path d="M12 12a1 1 0 0 1 .993 .883l.007 .117v3a1 1 0 0 1 -1.993 .117l-.007 -.117v-2a1 1 0 0 1 -.117 -1.993l.117 -.007h1z" /></svg>
                                {`${dayjs(rangeDate.startDate).format('DD/MM/YYYY')} - ${dayjs(rangeDate.endDate).format('DD/MM/YYYY')}`}
                            </button>
                            {open &&
                            <DateRange
                                editableDateInputs = {true}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                direction="horizontal"
                                ranges={[rangeDate]}
                                onChange={handleRangeDate}
                                className="calendarElement"
                            />}
                        </div>
                    </span>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Header