import {Navigate, Outlet} from 'react-router-dom';
import Menu from './menu';
import Header from './header';
import { useContext } from 'react';
import Toast from '../../components/toast/Toast';
import { ToastContext } from '../../utils/contexts/context';
import { useSelector } from 'react-redux';
import { token } from '../../redux/selectors';

function MainLayout() {
    const [toast, setToast] = useContext(ToastContext);

    const accessToken = useSelector(token);

    if(!accessToken){
        return <Navigate to="/login" />
    }

    return ( 
    <div className='page'>
        <Menu/>
        <Header/>
        <Outlet/>
        
        <Toast toast={toast} setToast={setToast}/>
    </div>
)}

export default MainLayout