import {createBrowserRouter, Navigate, Outlet} from 'react-router-dom';
import { AuthRoutes } from './AuthRoutes';
import { MainRoutes } from './MainRoutes';
import { PosRoutes } from './PosRoutes';
import Error from '../pages/errors/not-found/error-404';
import UnauthorizedPage from '../pages/errors/access/unauthorizedPage';

const redirect = {
    path: '/',
    exact: true,
    element: <Navigate to="/dashboard" />,
    errorElement: <Error/>
};

const unauthorized = {
    path: '/',
    element: <Outlet/>,
    children: [
        {
            path: '/unauthorized',
            element: <UnauthorizedPage/>
        }
    ]
}

export const router =  createBrowserRouter([redirect, AuthRoutes, PosRoutes, MainRoutes, unauthorized])