import React from 'react'
import { Link } from 'react-router-dom'

function TabSettings() {
  return (
    <div className="card-body">
        <h4 className="subheader">Réglage du compte</h4>
        <ul className="nav nav-tabs" style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <li className="nav-item">
                <Link className="nav-link active" id="tab-account" data-bs-toggle="tab" to="#content-account">
                    Profil
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" id="tab-fiche" data-bs-toggle="tab" to="#content-fiche">
                    Fiche de vente
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" id="tab-notifications" data-bs-toggle="tab" to="#content-notifications">
                    Notifications
                </Link>
            </li>
            <h4 className="subheader mt-4">Experience</h4>
            <li className="nav-item">
                <Link className="nav-link" id="tab-features" data-bs-toggle="tab" to="#content-features">
                    Fonctionnalités
                </Link>
            </li>
        </ul>
    </div>
  )
}

export default TabSettings