import React from 'react'

function CardReportSkeleton() {
  return (
    <div className="card card-stats mb-xl-0">
        <div className="card-body placeholder-glow" style={{width: '250px'}}>
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0 placeholder placeholder-xs col-6">''</h5>
                    <span className="h2 font-weight-bold mb-0 placeholder placeholder-xs col-7"></span>
                </div>
                <div className="col-auto">
                    <span className="avatar placeholder"></span>
                </div>
            </div>
            <p className="mt-5 mb-0 text-muted text-sm">
                <span className="placeholder placeholder-xs col-9"></span>
            </p>
        </div>
    </div>
  )
}

export default CardReportSkeleton