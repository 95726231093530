import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../../environments/environments';

const url = env;

const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['Category'],
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: (filter) => ({
                url: '/categories',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Category']
        }),

        getOneCategory : builder.query({
            query: (id) => ({
                url: `/categories/${id}`,
                method: 'GET'
            }),
            providesTags: ['Category']
        }),

        createCategory : builder.mutation({
            query: (category) => ({
                url: '/categories/create',
                method: 'POST',
                body: category
            }),
            invalidatesTags: ['Category']
        }),

        updateCategory : builder.mutation({
            query: ({id, category}) =>({
                url: `/categories/${id}`,
                method: 'PUT',
                body: category
            }),
            invalidatesTags: ['Category']
        }),

        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `/categories/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Category']
        }),

        getReportCategories: builder.query({
            query: (filter) => ({
                url: '/categories/sales/reports',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Category']
        })
    })
})

export const {
    useGetCategoriesQuery,
    useGetOneCategoryQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useGetReportCategoriesQuery
} = categoryApi;

export default categoryApi