import CardReportSkeleton from "../../../../components/skeleton/CardReportSkeleton"

function TotalDette({isLoading, dette, duree}) {

  if(isLoading){
    return(
      <CardReportSkeleton/>
    )
  }

  return (
    <div className="card card-stats mb-xl-0">
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Balance</h5>
                    <span className="h2 font-weight-bold mb-0">{dette}</span>
                </div>
                <div className="col-auto">
                    <span className="bg-red text-white avatar">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-cash-off"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M13 9h6a2 2 0 0 1 2 2v6m-2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" /><path d="M12.582 12.59a2 2 0 0 0 2.83 2.826" /><path d="M17 9v-2a2 2 0 0 0 -2 -2h-6m-4 0a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" /><path d="M3 3l18 18" /></svg>
                    </span>
                </div>
            </div>
            <p className="mt-5 mb-0 text-muted text-sm">
                <span className="text-nowrap">Depuis {duree}</span>
            </p>
        </div>
    </div>
  )
}

export default TotalDette