import { Outlet } from "react-router-dom";
import MainLayout from "../layout/mainLayout";
import Home from "../pages/dashboard/home/Home";
import Orders from "../pages/dashboard/orders/Orders";
import Magasins from "../pages/dashboard/magasins/Magasins";
import AddMagasin from "../pages/dashboard/magasins/mutation/AddMagasin";
// import Transferts from "../pages/dashboard/transferts/Transferts";
// import AddTransfert from "../pages/dashboard/transferts/mutation/AddTransfert";
import Clients from "../pages/dashboard/clients/Clients";
import Users from "../pages/dashboard/users/Users";
// import Ajustements from "../pages/dashboard/ajustements/Ajustements";
// import Ajuster from "../pages/dashboard/ajustements/mutation/Ajuster";
import AddUser from "../pages/dashboard/users/mutation/AddUser";
import Etiquette from "../pages/dashboard/etiquette.js/Etiquette";
import Categories from "../pages/dashboard/categories/Categories";
import DailyReports from "../pages/dashboard/rapports/DailyReports";
import CategoryReports from "../pages/dashboard/rapports/CategoryReports";
import ProductReports from "../pages/dashboard/rapports/ProductReports";
import CustomerReports from "../pages/dashboard/rapports/CustomerReports";
import Paiements from "../pages/dashboard/paiements/Paiements";
import AddPaiement from "../pages/dashboard/paiements/mutation/AddPaiement";
import Settings from "../pages/dashboard/settings";
import Roles from "../pages/dashboard/roles/Roles";
import AddRole from "../pages/dashboard/roles/mutation/AddRole";
import AddArticle from "../pages/dashboard/articles/mutation/AddArticle";
import Articles from "../pages/dashboard/articles/Articles";
import UpdateArticle from "../pages/dashboard/articles/mutation/UpdateArticle";
import AddClient from "../pages/dashboard/clients/mutation/AddClient";
import Error from "../pages/errors/not-found/error-404";
import ProtectedRoute from "./ProtectedRoute";
import UpdateMagasin from "../pages/dashboard/magasins/mutation/UpdateMagasin";
import UpdateClient from "../pages/dashboard/clients/mutation/UpdateClient";
import UpdateUser from "../pages/dashboard/users/mutation/UpdateUser";

export const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    errorElement: <Error/>,
    children: [
        {
            path: '/dashboard',
            element: <Home/>
        },{
            path: '/',
            element: <Outlet/>,
            children:[
                {
                    path: '/magasins/list',
                    element: <ProtectedRoute
                        element={<Magasins/>}
                        routePath='magasins'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/magasins/ajouter',
                    element: <ProtectedRoute
                        element={<AddMagasin/>}
                        routePath='magasins'
                        privilegeType= 'haveAdd'
                    />
                },{
                    path: '/magasins/edit/:id',
                    element: <ProtectedRoute
                        element={<UpdateMagasin/>}
                        routePath='magasins'
                        privilegeType= 'haveEdit'
                    />
                }
            ]
        },{
            path: '/',
            element: <Outlet/>,
            children: [
                {
                    path: '/articles/list',
                    element: <ProtectedRoute
                        element={<Articles/>}
                        routePath='articles'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/articles/ajouter',
                    element: <ProtectedRoute
                        element={<AddArticle/>}
                        routePath='articles'
                        privilegeType= 'haveAdd'
                    />
                },{
                    path: '/articles/edit/:id',
                    element: <ProtectedRoute
                        element={<UpdateArticle/>}
                        routePath='articles'
                        privilegeType= 'haveEdit'
                    />
                },{
                    path: '/articles/etiquette',
                    element: <ProtectedRoute
                        element={<Etiquette/>}
                        routePath='articles'
                        privilegeType= 'haveAdd'
                    />
                }
            ]
        },{
            path: '/',
            element: <Outlet/>,
            children: [
                {
                    path: '/articles/categories/list',
                    element: <ProtectedRoute
                        element={<Categories/>}
                        routePath='articles'
                        privilegeType= 'haveView'
                    />
                }
            ]
        },{
            path: '/',
            element: <Outlet/>,
            children: [
                {
                    path: '/orders/list',
                    element: <ProtectedRoute
                        element={<Orders/>}
                        routePath='ventes'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/orders/payments',
                    element: <ProtectedRoute
                        element={<Paiements/>}
                        routePath='paiements'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/orders/payments/ajouter',
                    element: <ProtectedRoute
                        element={<AddPaiement/>}
                        routePath='paiements'
                        privilegeType= 'haveAdd'
                    />
                }
            ]
        },
        // {
        //     path: '/',
        //     element: <Outlet/>,
        //     children: [
        //         {
        //             path: '/transferts/list',
        //             element: <ProtectedRoute
        //                 element={<Transferts/>}
        //                 routePath='transferts'
        //                 privilegeType= 'haveView'
        //             />
        //         },{
        //             path: '/transferts/ajouter',
        //             element: <ProtectedRoute
        //                 element={<AddTransfert/>}
        //                 routePath='transferts'
        //                 privilegeType= 'haveAdd'
        //             />
        //         }
        //     ]
        // },{
        //     path: '/',
        //     element: <Outlet/>,
        //     children: [
        //         {
        //             path: '/ajustements/list',
        //             element: <ProtectedRoute
        //                 element={<Ajustements/>}
        //                 routePath='ajuster'
        //                 privilegeType= 'haveView'
        //             />
        //         },{
        //             path: '/ajustements/ajouter',
        //             element: <ProtectedRoute
        //                 element={<Ajuster/>}
        //                 routePath='ajuster'
        //                 privilegeType= 'haveAdd'
        //             />
        //         }
        //     ]
        // },
        {
            path: '/',
            element: <Outlet/>,
            children:[
                {
                    path: '/clients/list',
                    element: <ProtectedRoute
                        element={<Clients/>}
                        routePath='clients'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/clients/ajouter',
                    element: <ProtectedRoute
                        element={<AddClient/>}
                        routePath='clients'
                        privilegeType= 'haveAdd'
                    />
                },{
                    path: '/clients/edit/:id',
                    element: <ProtectedRoute
                        element={<UpdateClient/>}
                        routePath='clients'
                        privilegeType= 'haveEdit'
                    />
                }
            ]
            
        },{
            path: '/',
            element: <Outlet/>,
            children: [
                {
                    path: '/utilisateurs/list',
                    element: <ProtectedRoute
                        element={<Users/>}
                        routePath='utilisateurs'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/utilisateurs/ajouter',
                    element: <ProtectedRoute
                        element={<AddUser/>}
                        routePath='utilisateurs'
                        privilegeType= 'haveAdd'
                    />
                },{
                    path: '/utilisateurs/edit/:id',
                    element: <ProtectedRoute
                        element={<UpdateUser/>}
                        routePath='utilisateurs'
                        privilegeType= 'haveEdit'
                    />
                }
            ]
        },{
            path: '/',
            element: <Outlet/>,
            children: [
                {
                    path: '/utilisateurs/roles/list',
                    element: <ProtectedRoute
                        element={<Roles/>}
                        routePath='roles'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/utilisateurs/roles/ajouter',
                    element: <ProtectedRoute
                        element={<AddRole/>}
                        routePath='roles'
                        privilegeType= 'haveAdd'
                    />
                }
            ]
        },{
            path: '/',
            element: <Outlet/>,
            children: [
                {
                    path: '/rapports/journalier',
                    element: <ProtectedRoute
                        element={<DailyReports/>}
                        routePath='rapports'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/rapports/categories',
                    element: <ProtectedRoute
                        element={<CategoryReports/>}
                        routePath='rapports'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/rapports/produits',
                    element: <ProtectedRoute
                        element={<ProductReports/>}
                        routePath='rapports'
                        privilegeType= 'haveView'
                    />
                },{
                    path: '/rapports/clients',
                    element: <ProtectedRoute
                        element={<CustomerReports/>}
                        routePath='rapports'
                        privilegeType= 'haveView'
                    />
                }
            ]
        },{
            path: '/reglages',
            element: <Settings/>
        }
    ]
}