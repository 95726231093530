import { useEffect, useRef, useState } from 'react';
import { useGetOneOrderQuery } from '../../../../utils/services/api/orderApi';
import { generatePagination } from '../../../../utils';
import dayjs from 'dayjs';
import Status from '../../../../components/tabler/Status';
import { useReactToPrint } from 'react-to-print';
import { InvoiceItem } from '../../../../components/invoice/InvoiceItem';

function ViewOrder({id}) {

    const {data: order} = useGetOneOrderQuery(id);

    const [carts, setCarts] = useState([]);

    useEffect(()=> {
        if(typeof order?.cart !== 'undefined'){
            setCarts(order?.cart);
        }
    },[order])


    // Pour la pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(4);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = carts?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(carts?.length/recordsPerPage);
    const numbers = generatePagination(currentPage, npage);

    // Printer
    const componentRef = useRef();
    const print = useReactToPrint({
        content: () => componentRef.current,
    });


  return (
    <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEnd" aria-labelledby="offcanvasEndLabel">
        <div className="offcanvas-header">
            <h2 className="offcanvas-title" id="offcanvasEndLabel">Facture {order?.id}</h2>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div>
                <div className="card">
                    <div className="card-header" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <h4 className="card-title">Details</h4>
                        <button className="btn btn-icon btn-ghost-secondary" onClick={()=> print()} data-bs-toggle="offcanvas" href="#offcanvasEnd" aria-controls="offcanvasEnd">
                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-printer"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" /></svg>
                        </button>
                    </div>
                    <div className="list-group list-group-flush list-group-hoverable">
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <span className="avatar" style={{backgroundImage: `url(${order?.imageUser})`}}></span>
                            </div>
                            <div className="col text-truncate">
                                <div className="text-reset d-block">Vendeur</div>
                                <div className="d-block text-muted text-truncate mt-n1">{order?.username} - {order?.telUser}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate">
                                <div className="text-reset d-block">Magasin</div>
                                <div className="d-block text-muted text-truncate mt-n1">{order?.magasin}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate">
                                <div className="text-reset d-block">Client</div>
                                <div className="d-block text-muted text-truncate mt-n1">{order?.nomCustomer} - {order?.telCustomer}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset ">Montant vente</div>
                            <div className=" text-muted text-truncate mt-n1">{(order?.montantVendu + order?.taxes) - order?.discount}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Montant versé</div>
                                <div className="text-muted text-truncate mt-n1">{order?.montantVerser - order?.monnaie}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Balance</div>
                                <div className="text-muted text-truncate mt-n1">{order?.montantDue}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Quantité</div>
                                <div className="text-muted text-truncate mt-n1">{order?.items}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Statut</div>
                                <div className="text-muted text-truncate mt-n1">
                                    <Status color={order?.statut==='paid' ? 'green' : 
                                        (order?.statut==='due' ? 'red' : 'yellow')}>
                                            {order?.statut==='paid' ? 'Payé' : 
                                        (order?.statut==='due' ? 'Dette' : 'Balance')}
                                    </Status>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Date Vente</div>
                                <div className="text-muted text-truncate mt-n1">{dayjs(order?.dateVente).format('DD-MM-YYYY . hh:mm a')}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="card" style={{marginTop: '20px'}}>
                    <div className="card-header">Panier</div>
                    <div className="table-responsive">
                        <table className="table table-vcenter card-table">
                            <thead>
                            <tr>
                                <th>Produit</th>
                                <th>Prix</th>
                                <th>Quantité</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                                {records?.map((item, i)=> (
                                    <tr key={i}>
                                        <td>
                                            <div className="d-flex py-1 align-items-center">
                                                <span className="avatar me-2" style={{backgroundImage: `url(${item.cover})`}}></span>
                                                <div className="flex-fill">
                                                    <div className="font-weight-medium">{item.nom}</div>
                                                </div>
                                        </div>
                                        </td>
                                        <td className="text-muted" >{item.prixVente}</td>
                                        <td className="text-muted" >{item.quantity}x</td>
                                        <td className="text-muted" >{item.prixVente*item.quantity}</td>
                                    </tr>
                                ))}

                                {carts?.length === 0 &&
                                    <tr>
                                        <td colspan={3} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer d-flex align-items-center">
                        <ul className="pagination m-0 ms-auto">
                            <li className="page-item">
                                <button onClick={prePage} className={`page-link ${currentPage===1 && 'disabled'}`} tabindex="-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                                </button>
                            </li>
                            {carts?.length!==0 &&
                            (numbers?.map((page, id)=> (
                                <li className={`page-item ${currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
                            )))}
                            <li className="page-item">
                                <button onClick={nextPage} className={`page-link ${currentPage===npage && 'disabled'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div style={{display: 'none'}}>
            <InvoiceItem ref={componentRef} list={carts}
                item={order?.items} totalSale={order?.montantVendu} discount={order?.discount}
                taxes={order?.taxes} salesPerson={order?.username} customer={`${order?.nomCustomer} - ${order?.telCustomer}`}
                montantDue={order?.montantDue} renduMonnaie={order?.monnaie} montantVerser={order?.montantVerser} idStore={order?.idMagasin}
            />
        </div>

    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    if(!(id==='...')){
        setCurrentPage(id)
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default ViewOrder