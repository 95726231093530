import React from 'react'

function Pagination(props) {
  return (
    <div className="d-flex me-5 mb-5">
        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button onClick={prePage} className={`page-link ${props.currentPage===1 && 'disabled'}`} tabindex="-1">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
            </button>
          </li>
          {!props.isLoading && (props.numbers?.map((page, id)=> (
            <li className={`page-item ${props.currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
          )))}
          <li className="page-item">
            <button onClick={nextPage} className={`page-link ${props.currentPage===props.npage && 'disabled'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
            </button>
          </li>
        </ul>
    </div>
  )

  function prePage(){
    if(props.currentPage !== 1){
      props.setCurrentPage(props.currentPage - 1)
    }
  }
  function changeCPage(id){
    props.setCurrentPage(id)
  }

  function nextPage(){
    if(props.currentPage !== props.npage){
      props.setCurrentPage(props.currentPage + 1)
    }
  }
}

export default Pagination