import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { user } from '../../../redux/selectors';

function Navigation() {
    const currentUser = useSelector(user);
  return (
    <header class="navbar navbar-expand-md d-print-none" data-bs-theme="dark">
        <div class="container-xl">
            <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                Belorest 
            </h1>
            <div class="navbar-nav flex-row order-md-last">
                <div class="nav-item dropdown">
                    <div style={{color: '#fff'}}>
                        <NavLink to="#1" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                            <span className="avatar avatar-sm" style={{backgroundImage: `url(${currentUser.imageUrl})`}}></span>
                            <div className="d-none d-xl-block ps-2">
                            <div>{currentUser.username}</div>
                            <div className="mt-1 small text-muted">{currentUser.role}</div>
                            </div>
                        </NavLink>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <NavLink to="/dashboard" className="dropdown-item">Fermer</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Navigation