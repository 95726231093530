
function Credit(props) {

  return (
    <div className="modal modal-blur fade" id="modal-credit" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Section Balance</h5>
                    <button type="button" className="btn-close" onClick={()=> props.setIsOpen(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form onSubmit={props.handleSubmit}>
                <div className="modal-body">
                    <div className='divide-x'
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', 
                        background: '#ffa500', padding: '10px', margin: '0px 0px 15px 0px', borderRadius: '3px', color: '#fff'}}>
                        <div>
                            <h4>Total Items</h4>
                            <h5>{props.items}x</h5>
                        </div>
                        <div>
                            <h4>Montant Vente</h4>
                            <h5>{props.totalSale}</h5>
                        </div>
                        <div>
                            <h4>Montant Verser</h4>
                            <h5>{props.montantVerser}</h5>
                        </div>
                        <div>
                            <h4>Monnaie</h4>
                            <h5>{props.renduMonnaie()}</h5>
                        </div>
                        <div>
                            <h4>Balance</h4>
                            <h5>{props.montantDue()}</h5>
                        </div>
                    </div>

                  <div className="row row-cards">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Montant</label>
                        <input type="text" className="form-control" value={props.montantVerser} 
                            onChange={(e)=> props.setMontantVerser(e.target.value)}/>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Methode de Paiement</label>
                        <select className='form-control form-select' value={props.methodePaiement} onChange={(e)=> props.setMethodePaiement(e.target.value)}>
                          <option value="cash">Cash</option>
                          <option value="creditCard">Carte de credit</option>
                          <option value="none">Credit</option>
                        </select>
                    </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="mb-3">
                        <label className="form-label">Note de Paiement</label>
                        <textarea rows="5" className='form-control'  placeholder="Here can be your description">
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                    {props.isOpen && 
                    <button type="button" className="btn btn-secondary" 
                    data-bs-dismiss="modal" onClick={()=> props.setIsOpen(false)}
                    >Close</button>}

                    {!props.isOpen &&
                    <button type="submit" className="btn btn-primary">Valider</button>}
                </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Credit