import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Body from './components/Body'
import { useGetProductsQuery } from '../../utils/services/api/productApi';
import Pagination from './components/Pagination';
import PageLoader from '../../components/page-loader/PageLoader';
import Navigation from './components/Navigation';
import { generatePagination } from '../../utils';
import { useContext } from 'react';
import Toast from '../../components/toast/Toast';
import { ToastContext } from '../../utils/contexts/context';
import BarcodeReader from 'react-barcode-reader';
import { useDispatch, useSelector } from 'react-redux';
import { cartSlice } from '../../redux/slices/CartSlice';
import { getCarts, idStoreCurrent, magasins } from '../../redux/selectors';

function Pos() {
  
  const [toast, setToast] = useContext(ToastContext);

  const idStore = useSelector(idStoreCurrent);
  const listMagasin = useSelector(magasins);
  const currentStore = listMagasin?.find(item => item.id === idStore);

  const [filter, setFilter] = useState({
    query : '',
    nomCategorie: '',
    nomStore : currentStore?.nomStore,
    statut: true
  })

  useEffect(()=> {
    setCurrentPage(1)
  }, [filter])

  const {data: products, isLoading} = useGetProductsQuery(filter);

  // Pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  let recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = products?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(products?.length/recordsPerPage);
  const numbers = generatePagination(currentPage, npage);

  const [client, setClient] = useState({
    id: 1,
    nom: '',
    prenom: ''
  });

  // ****** Scanner pour le panier  ******
  const dispatch = useDispatch();
  const panier = useSelector(getCarts);

  const handleScan = (data) => {
    const articleScan = products?.find((item)=> item.barcode === data);
    if (articleScan === undefined) {
      setToast({
        ...toast,
        isOpen: true,
        severity: 'warning',
        message: 'Produit non trouvé'
      })
    }else{
      // On recupère dans le panier l'article qui corresponds à l'article scanner 
      // pour verifier la quantité stock et celle deja au panier.
      const articlePanier = panier.find((item)=> item.articleId === articleScan?.id);
      
      if(articleScan.suiviStock){
        if(articleScan.enStock===0 || articleScan.enStock===articlePanier?.qty){
          setToast({
            ...toast,
            isOpen: true,
            severity: 'danger',
            message: 'Ce produit est en rupture de stock'
          })
        }else{
          dispatch(
            cartSlice.actions.addToCart({articleId: articleScan?.id, title: articleScan?.nomArticle, category: articleScan?.nomCategorie, 
              variante: articleScan?.variante, price: articleScan?.prixVente, image: articleScan?.imageUrl}));
            setToast({
              ...toast,
              isOpen: true,
              severity: 'success',
              message: 'Produit ajouté au panier'
            })
        }
        
      }else{
        dispatch(
          cartSlice.actions.addToCart({articleId: articleScan?.id, title: articleScan?.nomArticle, category: articleScan?.nomCategorie, 
            variante: articleScan?.variante, price: articleScan?.prixVente, image: articleScan?.imageUrl}));
          setToast({
            ...toast,
            isOpen: true,
            severity: 'success',
            message: 'Produit ajouté au panier'
          })
      }
    }

  };

  const handleError = (err) => {
    console.error(err);
  };
  // **** End Barcode *****

  return !isLoading ? (
    <div className="page" style={{background: '#fff'}}>
      <Navigation/>
      <BarcodeReader
        onError={handleError}
        onScan={handleScan}
      />
      <div className="page-wrapper">
        <Header data={products} nomStore={currentStore?.nomStore} filter={filter} setFilter={setFilter} client={client} setClient={setClient}/>
        <Body products={records} isLoading={isLoading} client={client}/>
        <Pagination numbers={numbers} isLoading={isLoading} npage={npage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
      </div>

      <Toast toast={toast} setToast={setToast}/>
    </div>
  ) : 
  (<>
    <PageLoader/>
  </>)
}

export default Pos