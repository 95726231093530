import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../../../components/header-page/Header'
import { useCreateMagasinMutation } from '../../../../utils/services/api/magasinsApi'
import { zodResolver } from '@hookform/resolvers/zod'
import { magasinSchema } from '../../../../utils/schemas/magasinSchema'
import { useForm } from 'react-hook-form'
import { ToastContext } from '../../../../utils/contexts/context'
import { randomNumberInRange } from '../../../../utils'

function AddMagasin() {

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  let ref = `Ref-${randomNumberInRange(100, 1000)}`;

  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: zodResolver(magasinSchema),
    defaultValues: {
      nomStore: '',
      etat: '',
      ville: '',
      codePostal: '',
      repere: '',
      pays: '',
      tel: 0,
      email: '',
      statut: true
    }
  })

  const [createMagasin] = useCreateMagasinMutation();

  const onSubmit = (data) => {
    let magasin = {
      ...data,
      ref: ref,
      companyId: 1
    }

    createMagasin(magasin)
    .then((resp)=> {
      if(resp.data.message){ 
        setToast({
          ...toast,
          isOpen: true,
          severity: 'success',
          message: resp.data.message
        })
        navigate('/magasins/list');
      }else{
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.errors[0].message
        })
      }
    }).catch((error)=> {
      console.log(error)
    })
  }

  
  return (
    <div className="page-wrapper">

        <Header title={'Ajouter Magasins'}/>

        <div className="page-body">
          <div className="container-xl">
            <div className="card">

              <div className="card-header">
                <h3 className="card-title">Nouvel Emplacement</h3>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  <div className="row row-cards">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">Ref.</label>
                        <input type="text" className="form-control" defaultValue={ref} disabled="true" placeholder="Reference"/>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Nom Magasin</label>
                        <input type="text" className={`form-control ${errors.nomStore && 'is-invalid'}`} {...register('nomStore')} placeholder="StoreOne"/>
                        {errors.nomStore && <div class="invalid-feedback">{errors.nomStore.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Etat</label>
                        <input type="text" className={`form-control ${errors.etat && 'is-invalid'}`} placeholder="Nord" {...register('etat')}/>
                        {errors.etat && <div class="invalid-feedback">{errors.etat.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Ville</label>
                        <input type="text" className={`form-control ${errors.ville && 'is-invalid'}`} {...register('ville')} placeholder="Cap-Haitien"/>
                        {errors.ville && <div class="invalid-feedback">{errors.ville.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Code Postal</label>
                        <input type="text" className={`form-control ${errors.codePostal && 'is-invalid'}`} {...register('codePostal')} placeholder="HT-20134"/>
                        {errors.codePostal && <div class="invalid-feedback">{errors.codePostal.message}</div>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Repère</label>
                        <input type="text" className={`form-control ${errors.repere && 'is-invalid'}`} {...register('repere')} placeholder="14, Rue 23 C"/>
                        {errors.repere && <div class="invalid-feedback">{errors.repere.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Pays</label>
                        <input type="text" className={`form-control ${errors.pays && 'is-invalid'}`} {...register('pays')} placeholder="Haiti"/>
                        {errors.pays && <div class="invalid-feedback">{errors.pays.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Telephone</label>
                        <input type="number" className={`form-control ${errors.tel && 'is-invalid'}`} {...register('tel', {valueAsNumber: true})} placeholder="2202 3432"/>
                        {errors.tel && <div class="invalid-feedback">{errors.tel.message}</div>}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} {...register('email')} placeholder="contact@gmail.com"/>
                        {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-label">Statut</div>
                      <label className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" />
                        <span className="form-check-label">Ouverture du store</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                  <div class="d-flex">
                    <Link to="/articles/list" class="btn btn-link">Annuler</Link>
                    <button type="submit" class="btn btn-primary ms-auto">Valider</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
    </div>
  )
}

export default AddMagasin