import { useEffect, useState } from 'react'
import Header from '../../../components/header-page/Header'
import { useGetProductsForGenerateLabelsQuery } from '../../../utils/services/api/productApi'
import { useDebouncedCallback } from 'use-debounce';
import dayjs from 'dayjs';
import { randomNumberInRange } from '../../../utils';
import ViewBarCode from './components/ViewBarCode';

function Etiquette() {
  
  const [filter, setFilter] = useState({
    query: '',
    nomStore: ''
  })

  const handleSearch = useDebouncedCallback((e) =>{
    e.preventDefault();
    setFilter({...filter, query: e.target.value})
  }, 100);

  const {data: products, isLoading} = useGetProductsForGenerateLabelsQuery(filter)
  
  const [idSelected, setIdSelected] = useState(0);
  const [productSelected, setProductSelected] = useState({})
  const handleCheck = (data) => {
    if(data===0){
      setIdSelected(0);
      setProductSelected({});
    }else{
      setIdSelected(data.id);
      setProductSelected(data);
    }
  }

  // On les reinitialise si le filter est modifier
  useEffect(()=> {
    setIdSelected(0)
    setProductSelected({})
  }, [filter])

  const [code, setCode] = useState();
  useEffect(()=> {
    if(idSelected!==0){
      if(productSelected.barcode === null){
        setCode(`${randomNumberInRange(10,10000)}${randomNumberInRange(100,25000)}`);
      }else{
        setCode(productSelected.barcode)
      }
    }
  }, [idSelected,productSelected])



  const handlePrintLabel = ()=> {
    
  }

  const [show, setShow] = useState({
    viewNomProduct : true,
    sizeNomProduct: 17,
    viewVarianteProduct : true,
    sizeVarianteProduct: 15,
    viewPriceProduct : true,
    sizePriceProduct: 15,
    viewNomStore : true,
    sizeNomStore: 20,
  })

  return (
    <div className="page-wrapper">
      <Header title={"Générer Etiquette"}/>
      <div className="page-body">
        <div className="container-xl">
          
          <div className="card">
            <div className="card-status-top bg-primary"></div>
            <div style={{margin: '20px 0px 0px 20px'}}>
              <h3 className="text-muted">Ajouter le produit pour générer son étiquette</h3>
            </div>
            <div className="card-body" style={{paddingLeft: '60px', paddingRight: '60px'}}>
              <div className="row row-cards">
                <div className="col-sm-12">
                  <div className="mb-3">
                    <div className="input-icon mb-3">
                      <span className="input-icon-addon">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                      </span>
                      <input type="text" className="form-control" onChange={handleSearch} placeholder="Saisie le nom du produit pour imprimer son étiquette"/>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card mb-3">
                    <div className="table-responsive">
                        <table className="table table-vcenter card-table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Produit</th>
                                <th>Variante</th>
                                <th>Prix</th>
                                <th>Date vente</th>
                            </tr>
                            </thead>
                            <tbody>
                                {isLoading &&
                                    <tr>
                                        <td colspan={5} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                                    </tr>
                                }
                                {products?.map((product, i)=> (
                                    <tr key={i}>
                                      <td>
                                        <input className="form-check-input m-0 align-middle"
                                          checked={idSelected === product.id} 
                                          onChange={(e) => handleCheck(e.target.checked ? product : 0)}
                                          type="checkbox" aria-label="Select all invoices"/>
                                      </td>
                                      <td className="text-muted">{product.nomArticle}</td>
                                      <td className="text-muted">{product.variante}</td>
                                      <td className="text-muted">{product.prixVente}</td>
                                      <td className="text-muted" >{dayjs(product.createdAt).format('DD-MM-YYYY . hh:mm a')}</td>
                                    </tr>
                                ))}
                                {products?.length === 0 &&
                                  <tr>
                                      <td colspan={5} className="text-muted" style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                                  </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card" style={{marginTop: '10px'}}>
            <div className="card-status-top bg-primary"></div>
            <div style={{margin: '20px 0px 0px 20px'}}>
              <h3 className="text-muted">Information à afficher sur l'etiquette</h3>
            </div>
            <div className="card-body" style={{paddingLeft: '60px', paddingRight: '60px'}}>
              <div className="row row-cards">
                <div className="col-sm-3">
                  <div className="mb-3">
                    <label className="form-check">
                      <input className="form-check-input" type="checkbox" checked={show.viewNomProduct} onChange={(e)=> setShow({...show, viewNomProduct: e.target.checked})}/>
                      <span className="h4 form-check-label" style={{fontWeight: 500}}>Nom Produit</span>
                    </label>
                    <div className="input-group mb-2">
                      <span className="input-group-text">
                        Size
                      </span>
                      <input type="number" className="form-control"  placeholder="subdomain" value={show.sizeNomProduct} onChange={(e)=> setShow({...show, sizeNomProduct: e.target.value})}/>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="mb-3">
                    <label className="form-check">
                      <input className="form-check-input" type="checkbox" checked={show.viewVarianteProduct} onChange={(e)=> setShow({...show, viewVarianteProduct: e.target.checked})}/>
                      <span className="h4 form-check-label" style={{fontWeight: 500}}>Variante Produit</span>
                    </label>
                    <div className="input-group mb-2">
                      <span className="input-group-text">
                        Size
                      </span>
                      <input type="number" className="form-control"  placeholder="subdomain" value={show.sizeVarianteProduct} onChange={(e)=> setShow({...show, sizeVarianteProduct: e.target.value})}/>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="mb-3">
                    <label className="form-check">
                      <input className="form-check-input" type="checkbox" checked={show.viewPriceProduct} onChange={(e)=> setShow({...show, viewPriceProduct: e.target.checked})}/>
                      <span className="h4 form-check-label" style={{fontWeight: 500}}>Prix Produit</span>
                    </label>
                    <div className="input-group mb-2">
                      <span className="input-group-text">
                        Size
                      </span>
                      <input type="number" className="form-control"  placeholder="subdomain" value={show.sizePriceProduct} onChange={(e)=> setShow({...show, sizePriceProduct: e.target.value})}/>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="mb-3">
                    <label className="form-check">
                      <input className="form-check-input" type="checkbox" checked={show.viewNomStore} onChange={(e)=> setShow({...show, viewNomStore: e.target.checked})}/>
                      <span className="h4 form-check-label" style={{fontWeight: 500}}>Nom Emplacement</span>
                    </label>
                    <div className="input-group mb-2">
                      <span className="input-group-text">
                        Size
                      </span>
                      <input type="number" className="form-control"  placeholder="subdomain" value={show.sizeNomStore} onChange={(e)=> setShow({...show, sizeNomStore: e.target.value})}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-footer text-end">
              <div class="d-flex">
                <button onClick={()=> handlePrintLabel()} class="btn btn-primary ms-auto" 
                data-bs-toggle="modal" data-bs-target="#modal-simple"
                disabled={idSelected===0 ? true : false}>Générer</button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <ViewBarCode code={code} show={show} product={productSelected}/>
    </div>
  )
}

export default Etiquette