import React from 'react'

function Status({color, children}) {
  return (
    <span class={`status status-${color} status-lite`}>
        <span class="status-dot"></span>
        {children}
    </span>
  )
}

export default Status