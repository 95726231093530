import { z } from 'zod';

export const articleSchema = z.object({
    barcode: z.string().min(0),
    nomArticle: z.string().min(1, { message: 'Champs Obligatoire' }),
    categoryId: z.number({ required_error: 'Veillez saisir la categorie', invalid_type_error: 'Choisir une categorie' }),
    variante: z.string().min(1, { message: 'Champs Obligatoire' }),
    typeProduit: z.string().min(1, { message: 'Champs Obligatoire' }),
    prixVente: z.number({ required_error: 'Saisie le prix du produit' }).gte(1,{ message: 'Saisie le prix du produit' }),
    suiviStock: z.boolean().optional(),
    enStock: z.number().nonnegative({message: 'Cette valeur ne peut être inferieure à zero'}),
    stockFaible: z.number().nonnegative({message: 'Cette valeur ne peut être inferieure à zero'}),
    description: z.string().min(0).max(100, {message: 'Reduisez le nombre de caractères'}).optional(),
    imageUrl: z.instanceof(FileList).optional(),
    statut: z.boolean(),
    magasinId: z.number({ required_error: "Veillez choisir l'emplacement du store", invalid_type_error: "Choisir l'emplacement du store" })
})