import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../../environments/environments";

const url = env;

const productApi = createApi({
    reducerPath : 'productApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['Product'],
    endpoints: (builder) =>({
        getProducts : builder.query({
            query: (filter) => ({
                url: '/articles',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Product']
        }),

        getOneProduct : builder.query({
            query: (id)=> ({
                url: `/articles/${id}`,
                method: 'GET'
            }),
            providesTags: ['Product']
        }),

        getProductsOnlineByScan : builder.query({
            query: (filter) => ({
                url: 'articles/scan/barcode',
                method: 'GET',
                params: filter
            })
        }),

        createProduct : builder.mutation({
            query: (product) =>({
                url: '/articles/create',
                method: 'POST',
                body: product
            }),
            invalidatesTags: ['Product']
        }),

        updateProduct : builder.mutation({
            query: ({id, product})=> ({
                url: `/articles/${id}`,
                method: 'PUT',
                body: product
            }),
            invalidatesTags: ['Product']
        }),

        deleteProduct: builder.mutation({
            query: (id) => ({
                url : `/articles/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Product']
        }),

        getReportProduct : builder.query({
            query: (filter) => ({
                url: '/articles/sales/reports',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Product']
        }),

        getStockProduct : builder.query({
            query: (filter) => ({
                url: '/articles/reports/stock',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Product']
        }),

        getPopularProducts: builder.query({
            query: (filter)=> ({
                url: '/articles/popularSales/reports',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Product']
        }),

        getProductsForGenerateLabels: builder.query({
            query: (filter) => ({
                url: '/articles/generate/labels',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Product']
        })
    })
})

export const {
    useGetProductsQuery,
    useGetOneProductQuery,
    useGetProductsOnlineByScanQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useGetReportProductQuery,
    useGetStockProductQuery,
    useGetPopularProductsQuery,
    useGetProductsForGenerateLabelsQuery
} = productApi;

export default productApi