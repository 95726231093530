import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../../environments/environments";

const url = env;

const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['order'],
    endpoints: (builder)=>({
        getOrders : builder.query({
            query: (filter)=> ({
                url : '/orders',
                method: 'GET',
                params: filter
            }),
            providesTags: ['order']
        }),

        getOneOrder : builder.query({
            query: (id) => ({
                url : `/orders/${id}`,
                method: 'GET'
            }),
            providesTags: ['order']
        }),

        createOrder : builder.mutation({
            query: (order) => ({
                url: '/orders/create',
                method: 'POST',
                body: order
            }),
            invalidatesTags: ['order']
        }),

        deleteOrder: builder.mutation({
            query: (id) => ({
                url: `/orders/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['order']
        }),

        getReportSales: builder.query({
            query: (filter) => ({
                url: '/orders/sales/reports',
                method: 'GET',
                params: filter
            }),
            providesTags: ['order']
        }),

        getOrderByMonth: builder.query({
            query: (filter)=> ({
                url: '/orders/sales/reports/byMonth',
                method: 'GET',
                params: filter
            }),
            providesTags: ['order']
        }),

        getOrderByDay: builder.query({
            query: (filter) => ({
                url: '/orders/sales/reports/byDay',
                method: 'GET',
                params: filter
            }),
            providesTags: ['order']
        }),

        getOrderDue: builder.query({
            query: (filter) => ({
                url: '/orders/balance/list',
                method: 'GET',
                params: filter
            }),
            providesTags: ['order']
        }),

    })
})

export const {
    useGetOrdersQuery,
    useGetOneOrderQuery,
    useCreateOrderMutation,
    useDeleteOrderMutation,
    useGetReportSalesQuery,
    useGetOrderByMonthQuery,
    useGetOrderByDayQuery,
    useGetOrderDueQuery,
} = orderApi;

export default orderApi;