import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: null,
        privileges: null,
        magasins: null,
        currentIdStore: null
    },
    reducers: {
        setCredential: (currentState, action) => {
            currentState.user = action.payload.user;
            currentState.token = action.payload.token;
            currentState.privileges = action.payload.privileges;
            currentState.magasins = action.payload.magasins;
        },
        logout: (currentState) => {
            currentState.user = null;
            currentState.token = null;
            currentState.privileges = null;
            currentState.magasins = null;
            currentState.currentIdStore = null;
        },
        currentStore: (currentState, action) => {
            currentState.currentIdStore = action.payload.id
        }
    }
})