import { useState } from "react";
import { useForm } from "react-hook-form";

function AddNote(props) {

    const {register ,handleSubmit} = useForm()

    const [isSuccess, setIsSuccess] = useState(false);

    const onSubmit = (data) =>{
        setIsSuccess(true)
        props.setNotes(data.notes)
    }

  return (
    <div className="modal modal-blur fade" id="modal-notes" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Ajouter notes de vente</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-body">
                        <div class="mb-1">
                            <label className="form-label">Notes</label>
                            <textarea rows="5" className="form-control" defaultValue={props.notes} {...register('notes')}>
                            </textarea>
                        </div>
                    </div>
                    
                    <div className="modal-footer">
                        <button type="button" 
                        onClick={()=> setIsSuccess(false)} className="btn me-auto" data-bs-dismiss="modal">Close</button>
                       {!isSuccess && <button type="submit" className="btn btn-primary">Appliquer</button>}
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default AddNote