import React, { useEffect } from 'react';
import Alert from '../tabler/Alert';
import './toast.css';

function Toast({toast, setToast}) {

    useEffect(()=>{
        const timeout = setTimeout(()=>{
            setToast({...toast, isOpen: false})
        },4000)
        return()=>{
            clearTimeout(timeout)
        }
    }, [toast, setToast])

  return toast.isOpen ? (
    <Alert className="animated-alert" severity={toast.severity} message={toast.message}/>
  ) : (<></>)
}

export default Toast