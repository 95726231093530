export const getCarts = (state) => state?.cart;

export const items = (state) =>{ 
    return getCarts(state).reduce((total, item) => total + item.qty, 0);
}

export const total = (state) => {
    return getCarts(state).reduce((total, item) => {
        return Math.round((total + (item.price * item.qty)) * 100) / 100;
    }, 0);
}

export const token = (state) => state?.auth?.token;

export const user = (state) => state?.auth?.user;

export const privileges = (state) => state?.auth?.privileges;

export const magasins = (state) => state?.auth?.magasins;

export const idStoreCurrent = (state) => state?.auth?.currentIdStore;

export const draft = (state) => state?.draft;

export const suspend = (state) => state?.suspend;