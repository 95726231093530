import React from 'react'
import {Link, useLocation} from 'react-router-dom'

function Header({title}) {
  let location = useLocation();
  const pathnames = location.pathname.split('/').filter((x)=> x);

  return (
    <div className="page-header d-print-none">

          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title">
                  {title}
                </h2>
              </div>
            </div>
            
            <div className="row g-2 align-items-center mt-1">
              <div className="col">
                  <ol className="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                    {pathnames.map((path, index)=> (
                      <li className={index===pathnames.length-1 ? "breadcrumb-item active" : "breadcrumb-item"} key={index} aria-current="page"><Link to="#">{path}</Link></li>
                    ))}
                  </ol>
              </div>
            </div>
          </div>

        </div>
  )
}

export default Header