import React, { useState } from 'react'
import {Link} from 'react-router-dom';
import DailySales from './dailySales';
import Draft from './draft';
import Suspend from './suspend';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

function Repertories() {
    // Date vente 
    let today= dayjs().format('YYYY-MM-DD');
    
    const [dailySales, setDailySales] = useState([])

    const handleExport = ()=>{
        var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(dailySales);

        XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
        XLSX.writeFile(wb, 'Liste_Utilisateurs.xlsx');
    }

  return (
    <div className="modal modal-blur fade" id="modal-historique" tabindex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="card">
                <div className="card-header">
                    <ul className="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
                        <li className="nav-item">
                            <Link to="#tabs-home-8" className="nav-link active" data-bs-toggle="tab">Vente Journalière</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#tabs-profile-8" className="nav-link" data-bs-toggle="tab">Brouillon</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#tabs-activity-8" className="nav-link" data-bs-toggle="tab">Vente Suspendue</Link>
                        </li>
                    </ul>
                </div>
                <div className="card-body">
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="tabs-home-8">
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                    <h4 style={{fontWeight: 500}}>Historique des vente de la journée</h4>
                                    <h5 style={{fontWeight: 400}}>Date: {today}</h5>
                                </div>
                                <div>
                                    <button 
                                        onClick={()=> handleExport()}
                                        className='btn btn-success' data-bs-toggle="tooltip" data-bs-placement="bottom" title="Exportez en format Excel">
                                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-file-spreadsheet"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M8 11h8v7h-8z" /><path d="M8 15h8" /><path d="M11 11v7" /></svg>
                                        Export
                                    </button>
                                </div>
                            </div>
                            <DailySales date={today} setDailySales={setDailySales}/>
                        </div>
                        <div className="tab-pane fade" id="tabs-profile-8">
                            <h4>Historique des vente au brouillon</h4>
                            <Draft/>
                        </div>
                        <div className="tab-pane fade" id="tabs-activity-8">
                            <h4>Historique des vente mise en attente</h4>
                            <Suspend/>
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    <button type="button" className="btn me-auto" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Repertories