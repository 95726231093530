import { createContext, useState } from "react"

export const ToastContext = createContext()
export function ToastProvider({children}){
    const toast = useState({
        isOpen: false,
        severity: '',
        message: '',
    })
    return(
        <ToastContext.Provider value={toast}>
            {children}
        </ToastContext.Provider>
    )
}