import { zodResolver } from '@hookform/resolvers/zod';
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { clientSchema } from '../../../utils/schemas/clientSchema';
import { ToastContext } from '../../../utils/contexts/context';
import { randomNumberInRange } from '../../../utils';
import { useCreateClientMutation } from '../../../utils/services/api/clientApi';

function AddCustomer() {

    const [toast, setToast] = useContext(ToastContext);

    let code = `Cl-${randomNumberInRange(100, 1000)}`;

    const {handleSubmit, register, formState: {errors}, reset} = useForm({
        resolver: zodResolver(clientSchema),
        defaultValues: {
            type_client: 'particulier',
            nom: '',
            prenom: '',
            email: '',
            tel: 0,
            adresse: '',
        }
    })

    const [isSuccess, setIsSuccess] = useState(false);

    const [createClient] = useCreateClientMutation();

    const onSubmit = (data) =>{
        let client = {
            ...data,
            code: code,
          }
      
          createClient(client)
          .then((resp)=> {
            if(resp.data.message){ 
              setToast({
                ...toast,
                isOpen: true,
                severity: 'success',
                message: resp.data.message
              })
              setIsSuccess(true);
              reset();
            }else{
              setToast({
                ...toast,
                isOpen: true,
                severity: 'danger',
                message: resp.data.errors[0].message
              })
            }
          }).catch((error)=> {
            console.log(error)
          })
    }

  return (
    <div className="modal modal-blur fade" id="modal-AddCustomer" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Ajouter Client</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                  <div className="row row-cards">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" defaultValue={code} disabled={true}/>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Nom</label>
                        <input type="text" className={`form-control ${errors.nom && 'is-invalid'}`} placeholder="nom" {...register('nom')}/>
                        {errors.nom && <div class="invalid-feedback">{errors.nom.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Prenom</label>
                        <input type="text" className={`form-control ${errors.prenom && 'is-invalid'}`} placeholder="prenom" {...register('prenom')} />
                        {errors.prenom && <div class="invalid-feedback">{errors.prenom.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Type Client</label>
                        <select className={`form-control form-select ${errors.type_client && 'is-invalid'}`}  {...register('type_client')}>
                          <option></option>
                          <option value="particulier">Particulier</option>
                          <option value="entreprise">Entreprise</option>
                        </select>
                        {errors.type_client && <div class="invalid-feedback">{errors.type_client.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder="customer@gmail.com" {...register('email')}/>
                        {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Tel</label>
                        <input type="text" className={`form-control ${errors.tel && 'is-invalid'}`} {...register('tel', {valueAsNumber: true})}/>
                        {errors.tel && <div class="invalid-feedback">{errors.tel.message}</div>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Address</label>
                        <input type="text" className={`form-control ${errors.adresse && 'is-invalid'}`} placeholder="Rue 2L, Cap-Haitien, Haiti" {...register('adresse')}/>
                        {errors.adresse && <div class="invalid-feedback">{errors.adresse.message}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                    {isSuccess && 
                    <button type="button" className="btn btn-secondary" 
                    data-bs-dismiss="modal" onClick={()=> setIsSuccess(false)}
                    >Close</button>}

                    {!isSuccess &&
                    <button type="submit" className="btn btn-primary" 
                    onClick={(e)=> onSubmit(e)}>Add</button>}
                </div>
              </form>
            </div>
        </div>
    </div>
  )
}

export default AddCustomer