import { z } from 'zod';

export const userSchema = z.object({
    nom: z.string().min(1, { message: 'Champs Obligatoire' }),
    prenom: z.string().min(1, { message: 'Champs Obligatoire' }),
    email: z.string().email({ message: "Format d'email incorrect" }),
    tel: z.number({required_error: 'Champs Obligatoire', invalid_type_error: 'Chiffres Uniquement'}).gte(7, { message: 'Minimum 7 chiffres' }).positive(),
    username: z.string().min(1, { message: 'Champs Obligatoire' }),
    password: z.string().min(1, { message: 'Champs Obligatoire' }).max(20),
    statut:z.boolean(),
    imageUrl: z.instanceof(FileList).optional(),
    magasins: z.array(
        z.number()
    ).min(1, {message: 'Veillez selectionner au moins un magasin'}),
    roleId: z.number({ required_error: "Veillez choisir le role de l'utlisateur", invalid_type_error: "Choisir le role de l'utilisateur" })
})