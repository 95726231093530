import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../components/tabler/card';
import Cart from './Cart';
import { cartSlice } from '../../../redux/slices/CartSlice';
import MiniCart from './MiniCart';
import { getCarts } from '../../../redux/selectors';
import { useContext } from 'react';
import { ToastContext } from '../../../utils/contexts/context';

function Body(props) {

  const [toast, setToast] = useContext(ToastContext);

  const dispatch = useDispatch();

  const panier = useSelector(getCarts);
  const Added = (articleId, title, category, variante, price, image, suivieStock, enStock) => {
    const item = panier.find((item)=> item.articleId === articleId);
    if(item !== undefined){
      if(suivieStock && enStock===item.qty){
        setToast({
          ...toast,
          isOpen: true,
          severity: 'warning',
          message: 'Vous avez atteint la limite du stock!'
        })
      }else{
        dispatch(cartSlice.actions.addToCart({articleId, title, category, variante, price, image}))
      }
    }else{
      dispatch(cartSlice.actions.addToCart({articleId, title, category, variante, price, image}))
    }
  }

  return (
    <div className="page-body">
          <div className="container-xl">
            <div className="row g-4">
              
              <div className="col-12 col-md-8">
                <div className="row row-cards">
                  
                  {props.products?.map((product, i)=> (
                    <div className="col-sm-6 col-lg-4" key={i}>
                        <Card cover={product.imageUrl} title={product.nomArticle} category={product.nomCategorie} 
                          price={product.prixVente} suiviStock={product.suiviStock} enStock={product.enStock} 
                          stockFaible={product.stockFaible} variante={product.variante}
                          onSelect={()=> Added(product.id, product.nomArticle, product.nomCategorie, product.variante, product.prixVente, product.imageUrl, product.suiviStock, product.enStock)}/>
                    </div>
                  ))}
                  
                </div>
              </div>

              <div className="d-none d-md-block col-md-4">
                <Cart client={props.client} products={props.products}/>
              </div>

            </div>
          </div>

          <div className='d-lg-none'>
              <MiniCart client={props.client} products={props.products}/>
          </div>

        </div>
  )
}

export default Body