import CardReportSkeleton from "../../../../components/skeleton/CardReportSkeleton"

function TotalProduit({isLoading, stock}) {

    if(isLoading){
        return(
            <CardReportSkeleton/>
        )
    }

  return (
    <div className="card card-stats mb-xl-0">
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Stock</h5>
                    <span className="h2 font-weight-bold mb-0">{stock}</span>
                </div>
                <div className="col-auto">
                    <span className="bg-green text-white avatar">
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-archive"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" /><path d="M10 12l4 0" /></svg>
                    </span>
                </div>
            </div>
            <p className="mt-5 mb-0 text-muted text-sm">
                <span className="text-nowrap">Produits en magasin</span>
            </p>
        </div>
    </div>
  )
}

export default TotalProduit