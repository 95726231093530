import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'
import { token } from '../../redux/selectors';

function PosLayout() {

    const accessToken = useSelector(token);

    if(!accessToken){
        return <Navigate to="/login" />
    }

  return (
    <div>
        <Outlet/>
    </div>
  )
}

export default PosLayout