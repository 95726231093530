import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../../environments/environments';

const url = env;

const magasinsApi = createApi({
    reducerPath: 'magasinsApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['Magasin'],
    endpoints: (builder)=>({
        getMagasins : builder.query({
            query: (filter)=> ({
                url: '/magasins',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Magasin']
        }),

        getOneMagasin: builder.query({
            query: (id)=> ({
                url: `/magasins/${id}`,
                method: 'GET'
            }),
            providesTags: ['Magasin']
        }),

        createMagasin : builder.mutation({
            query: (magasin) => ({
                url: '/magasins/create',
                method: 'POST',
                body: magasin
            }),
            invalidatesTags: ['Magasin']
        }),

        updateMagasin : builder.mutation({
            query: ({id, magasin}) => ({
                url: `/magasins/edit/${id}`,
                method: 'PUT',
                body: magasin
            }),
            invalidatesTags: ['Magasin']
        }),

        deleteMagasin: builder.mutation({
            query: (id)=>({
                url: `/magasins/remove/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Magasin']
        })
    })
})

export const {
    useGetMagasinsQuery,
    useGetOneMagasinQuery,
    useCreateMagasinMutation,
    useUpdateMagasinMutation,
    useDeleteMagasinMutation
} = magasinsApi;

export default magasinsApi;