import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        addToCart : (currentState, action) => {
            const { payload } = action;
            const productExists = currentState.find(product => product.title === payload.title && product.category===payload.category && product.prixVente === payload.prixVente && product.variante === payload.variante);
        
            if (productExists) {
                // Si le produit existe déjà, augmenter la quantité
                return currentState.map(product =>
                    (product.title === payload.title && product.category===payload.category && product.prixVente === payload.prixVente && product.variante === payload.variante) ? { ...product, qty: product.qty + 1 } : product
                );
            } else {
                // Si le produit n'existe pas encore, l'ajouter avec une quantité de 1
                return [...currentState, { ...payload, qty: 1 }];
            }
        },

        cleanCart: () => {
            return []
        },

        incrementQuantity: (state, action) => {
            return state.map(item =>
                item.title === action.payload.title ? { ...item, qty: item.qty + 1 } : item
            );
        },

        decrementQuantity: (state, action) => {
            return state.map(item => {
                if (item.title === action.payload.title) {
                    const newQuantity = Math.max(0, item.qty - 1);
                    return newQuantity === 0 ? null : { ...item, qty: newQuantity };
                }
                return item;
            }).filter(item => item !== null);
        },

        applyDiscount: (currentState, action) => {
            const listWithDiscount = currentState.map(
                (item)=> item.prix-action.payload);
            return listWithDiscount;
        }
    }
});
