import React from 'react'
import { useSelector } from 'react-redux';
import { privileges } from '../redux/selectors';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ element, routePath, privilegeType }) {

    const CurrentPrivileges = useSelector(privileges);
    
    // Trouver les privilèges pour le menu correspondant
    const privilege = CurrentPrivileges.find(p => p.menu === routePath);

    // Vérifier le privilège en fonction du type requis
    const hasPrivilege = privilege ? privilege[privilegeType] : false;

  return hasPrivilege ? element : (
    <Navigate to="/unauthorized" />
  )
}

export default ProtectedRoute