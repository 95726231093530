import BestProductSkeleton from '../../../../components/skeleton/BestProductSkeleton'

function BestProducts({ isLoading, products }) {

    if(isLoading){
        return(
        <BestProductSkeleton/>
    )}

  return (
    <div className="card">
        <div className="card-header">
            <h3 className="card-title">Stock Populaire</h3>
        </div>
        <table className="table card-table table-vcenter">
            <thead>
                <tr>
                    <th>Articles</th>
                    <th>Montant</th>
                </tr>
            </thead>
            <tbody>
                {products.slice(0,5).map((item, i)=>(
                <tr key={i}>
                    <td>
                        <div className="d-flex py-1 align-items-center">
                            <span className="avatar me-2" style={{backgroundImage: `url(${item.image})`}}></span>
                            <div className="flex-fill">
                                <div className="font-weight-medium">{item.nomArticle}</div>
                                <div className="text-muted">{item.nomCategorie}</div>
                            </div>
                        </div>
                    </td>
                    <td>{item.mtt}</td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default BestProducts