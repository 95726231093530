import TotalRevenu from './components/TotalRevenu';
import TotalVente from './components/TotalVente';
import TotalProduit from './components/TotalProduit';
import TotalDette from './components/TotalDette';
import SalesChartBar from './components/SalesChartBar';
import BestProducts from './components/BestProducts';
import { useGetOrderByMonthQuery, useGetReportSalesQuery } from '../../../utils/services/api/orderApi';
import { useGetPopularProductsQuery, useGetStockProductQuery } from '../../../utils/services/api/productApi';
import Header from './components/Header';
import { useSelector } from 'react-redux';
import { idStoreCurrent } from '../../../redux/selectors';
import { useState } from 'react';
import { timing } from '../../../utils';

function Home() {

  const idStore = useSelector(idStoreCurrent);

  const [filter, setFilter] = useState({
    idStore: idStore,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date()
  })

  const duree = timing(filter.startDate, filter.endDate);

  const {data: orderByMonth, isLoading: loadOrderByMonth} = useGetOrderByMonthQuery(filter);
  const {data: popularProduct, isLoading: loadPopularProduct} = useGetPopularProductsQuery(filter);
  const {data: reportSale, isLoading: loadReportSale} = useGetReportSalesQuery(filter);
  const {data: stockProduct, isLoading: loadStockProduct} = useGetStockProductQuery(filter);

  return (
    <div className="page-wrapper">

        {/* Page Header */}
        <Header filter={filter} setFilter={setFilter}/>
        {/* Page Body */}

      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            
            <div className="col-sm-6 col-lg-3">
              <TotalRevenu isLoading={loadReportSale} revenu={reportSale ? reportSale.totalEnCaisse : 0} duree={duree}/>
            </div>

            <div className="col-sm-6 col-lg-3">
              <TotalVente isLoading={loadReportSale} vente={reportSale ? (reportSale.totalVendu + reportSale.totalTaxes) - reportSale.totalDiscount : 0} duree={duree}/>
            </div>

            <div className="col-sm-6 col-lg-3">
              <TotalProduit isLoading={loadStockProduct} stock={stockProduct ? stockProduct.data : 0} duree={duree}/>
            </div>

            <div className="col-sm-6 col-lg-3">
              <TotalDette isLoading={loadReportSale} dette={reportSale ? reportSale.totalDue : 0} duree={duree}/>
            </div>

            <div className="col-lg-8">
              <SalesChartBar isLoading={loadOrderByMonth} orders={orderByMonth}/>
            </div>

            <div className="col-lg-4">
              <BestProducts isLoading={loadPopularProduct} products={popularProduct}/>
            </div>

          </div>
        </div>
      </div>
   </div>
  )
}

export default Home