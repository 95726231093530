import { useSelector } from 'react-redux'
import { draft } from '../../../../redux/selectors'
import { Link } from 'react-router-dom';

function Draft() {
  const currentDraft = useSelector(draft);

  return (
    <div className="card">
        <div className="table-responsive">
            <table className="table table-vcenter card-table">
                <thead>
                <tr>
                    <th>No Facture</th>
                    <th>Client</th>
                    <th>Montant</th>
                    <th>Quantité</th>
                    <th>Date</th>
                    <th className="w-1"></th>
                </tr>
                </thead>
                <tbody>
                    {currentDraft?.map((draft, i)=> (
                        <tr key={i}>
                            <td>{draft.noFacture}</td>
                            <td>{draft.customerFullName}</td>
                            <td className="text-muted" >{draft.montant}</td>
                            <td className="text-muted" >{draft.items}x</td>
                            <td className="text-muted" >{draft.date}</td>
                            <td><Link to="#">Reprendre</Link></td>
                        </tr>
                    ))}
                    {currentDraft?.length === 0 &&
                          <tr>
                            <td colSpan={6} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                          </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Draft