import * as XLSX from 'xlsx';
import { generatePagination } from '../../../utils';
import { useState } from 'react';
import Header from '../../../components/header-page/Header';
import { useGetReportProductQuery } from '../../../utils/services/api/productApi';
import dayjs from 'dayjs';
import { useDebouncedCallback } from 'use-debounce';

function ProductReports() {

  const [filter, setFilter] = useState({
    query: ''
  })

  const handleSearch = useDebouncedCallback((e) =>{
    e.preventDefault();
    setFilter({...filter, query: e.target.value})
  }, 100);

  const {data: products, isLoading} = useGetReportProductQuery(filter);
  
    let mtt = 0;
    let qty = 0;

    if(!isLoading){
        for(let i of products){
          mtt=mtt+i.mtt;
          qty=qty+i.quantity;
        }
    }
    

  // Pour exporter en format Excel
  const handleExport = ()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(products);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Rapport_Produit.xlsx');
  }

  // Pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = products?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(products?.length/recordsPerPage);
  const numbers = generatePagination(currentPage, npage);

  return (
    <div className="page-wrapper">
      <Header title={"Rapport Produit"}/>
      
      <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Rapports</h3>
                    <div className='ms-auto'>
                      <button className='btn btn-icon btn-success' onClick={()=>handleExport()} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Exportez en format Excel" style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-spreadsheet"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M8 11h8v7h-8z" /><path d="M8 15h8" /><path d="M11 11v7" /></svg>
                      </button>
                    </div>
                  </div>
                  <div className="card-body border-bottom py-3">
                    <div className="d-flex">
                      <div className="text-muted">
                        Show
                        <div className="mx-2 d-inline-block">
                            <select class="form-select" className="form-control form-control-sm" onChange={(e)=> setRecordsPerPage(e.target.value)}>
                              <option value={10}>10</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                        </div>
                        entries
                      </div>
                      <div className="ms-auto text-muted">
                        Search:
                        <div className="ms-2 d-inline-block">
                          <input type="text" className="form-control form-control-sm" 
                          onChange={handleSearch}
                          aria-label="Search invoice"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table card-table table-vcenter text-nowrap datatable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Facture</th>
                          <th>SKU</th>
                          <th>Produit</th>
                          <th>Montant Vendu</th>
                          <th>Quantité</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && 
                          <tr>
                            <td colSpan={6} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                          </tr>
                        }

                        {records?.map((product, i)=>(
                          <tr key={i}>
                            <td className="text-muted">{dayjs(product.dateVente).format('DD-MM-YYYY')}</td>
                            <td className="text-muted">{product.noFacture}</td>
                            <td>{product.SKU}</td>
                            <td className="text-muted">{product.nomArticle}</td>
                            <td className="text-muted">{product.mtt}</td>
                            <td className="text-muted">{product.quantity}</td>
                          </tr>
                        ))}

                        {products?.length>0 && !isLoading && 
                          <tr>
                            <td colSpan={4}>Total</td>
                            <td className="text-muted">{mtt}</td>
                            <td className="text-muted">{qty}</td>
                          </tr>
                        }

                        {products?.length === 0 &&
                          <tr>
                            <td colSpan={6} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">Showing <span>{currentPage}</span> to <span>{npage}</span></p>
                    <ul className="pagination m-0 ms-auto">
                      <li className="page-item">
                        <button onClick={prePage} className={`page-link ${currentPage===1 && 'disabled'}`} tabindex="-1">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                        </button>
                      </li>
                      {!isLoading &&
                      (numbers?.map((page, id)=> (
                        <li className={`page-item ${currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
                      )))}
                      <li className="page-item">
                        <button onClick={nextPage} className={`page-link ${currentPage===npage && 'disabled'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    if(!(id==='...')){
      setCurrentPage(id)
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default ProductReports