import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../../environments/environments";

const url = env;

const clientApi = createApi({
    reducerPath: 'clientApi',
    baseQuery: fetchBaseQuery({baseUrl: url}),
    tagTypes: ['Client'],
    endpoints: (builder) => ({
        getClients : builder.query({
            query: (filter)=> ({
                url: '/customers',
                method: 'GET',
                params: filter
            }),
            providesTags: ['Client']
        }), 

        getOneClient : builder.query({
            query : (id) => ({
                url: `/customers/${id}`,
                method: 'GET'
            }),
            providesTags: ['Client']
        }),

        createClient : builder.mutation({
            query: (client) => ({
                url: '/customers/create',
                method: 'POST',
                body: client
            }),
            invalidatesTags: ['Client']
        }),

        updateClient : builder.mutation({
            query: ({id, client}) => ({
                url: `/customers/${id}`,
                method: 'PUT',
                body: client
            }),
            invalidatesTags: ['Client']
        }),

        deleteClient : builder.mutation({
            query: (id) => ({
                url: `/customers/remove/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Client']
        }),

        getReportCustomer: builder.query({
            query : (filter) => ({
                url: `/customers/sales/reports`,
                method: 'GET',
                params: filter
            }),
            providesTags: ['Client']
        })
    })
})

export const {
    useGetClientsQuery,
    useGetOneClientQuery,
    useCreateClientMutation,
    useUpdateClientMutation,
    useDeleteClientMutation,
    useGetReportCustomerQuery
} = clientApi

export default clientApi;