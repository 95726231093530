import React, { useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import cover from '../../../assets/images/bg.jpg';
import logo from "../../../assets/images/BlueLogo.png";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { credentialSchema } from '../../../utils/schemas/credentialSchema';
import { useCreateConnectionMutation } from '../../../utils/services/api/userApi';
import { useDispatch } from 'react-redux';
import { authSlice } from '../../../redux/slices/authSlice';
import { ToastContext } from '../../../utils/contexts/context';

function Login() {

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  const dispatch = useDispatch();
  
  const [createConnection] = useCreateConnectionMutation();

  const {register, handleSubmit, formState: { errors }} = useForm({
    resolver: zodResolver(credentialSchema)
  })

  const onSubmit = (data) => {
   
    createConnection(data)
    .then(resp => {
      if(resp.data){
        if(resp.data.token){
          setToast({
            ...toast,
            isOpen: true,
            severity: 'success',
            message: "Connexion réussie"
          })

          dispatch(authSlice.actions.setCredential({
            user: resp.data.user,
            token: resp.data.token,
            privileges: resp.data.privileges,
            magasins: resp.data.magasins
          }))

          navigate('/dashboard')
        }else{
          setToast({
            ...toast,
            isOpen: true,
            severity: 'danger',
            message: resp.data.message
          })
        }
      }
    }).catch((error)=>{
      setToast({
        ...toast,
        isOpen: true,
        severity: 'danger',
        message: error.response.data.message
      })
    })
  }

  return (
    <div className=" d-flex flex-column bg-white">
      <div className="row g-0 flex-fill">
        <div className="col-12 col-lg-6 col-xl-4 border-top-wide border-primary d-flex flex-column justify-content-center">
          <div className="container container-tight my-5 px-lg-5">
            <div className="text-center mb-4">
              <NavLink to="." className="navbar-brand navbar-brand-autodark"><img src={logo} height="80" alt=""/></NavLink>
            </div>
            <h2 className="h3 text-center mb-3">
              Login to your account
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">Email address</label>
                <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder="email" {...register('email')}/>
                {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Password
                  <span className="form-label-description">
                    <NavLink to="./forgot-password.html">I forgot password</NavLink>
                  </span>
                </label>
                <div className="input-group input-group-flat">
                  <input type="password" className={`form-control ${errors.password && 'is-invalid'}`} placeholder="mot de passe" {...register('password')}/>
                  {errors.password && <div class="invalid-feedback">{errors.password.message}</div>}
                  <span className="input-group-text">
                    <NavLink to="#" className="link-secondary" title="Show password" data-bs-toggle="tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                    </NavLink>
                  </span>
                </div>
              </div>
              <div className="mb-2">
                <label className="form-check">
                  <input type="checkbox" className="form-check-input"/>
                  <span className="form-check-label">Remember me on this device</span>
                </label>
              </div>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary w-100">Sign in</button>
              </div>
            </form>
            <div className="text-center text-muted mt-3">
              Don't have account yet? <NavLink to="./sign-up.html" tabindex="-1">Sign up</NavLink>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-8 d-none d-lg-block">
          <div className="bg-cover h-100 min-vh-100" style={{backgroundImage: `url(${cover})`}}></div>
        </div>
    </div>
    </div>
  )
}

export default Login