import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCarts, idStoreCurrent, items, total, user } from '../../../redux/selectors';
import { cartSlice } from '../../../redux/slices/CartSlice';
import { randomNumberInRange } from '../../../utils';
import { ToastContext } from '../../../utils/contexts/context';
import { useCreateOrderMutation } from '../../../utils/services/api/orderApi';
import { useReactToPrint } from 'react-to-print';
import { Invoice } from '../../../components/invoice/Invoice';
import { draftSlice } from '../../../redux/slices/DraftSlice';
import { suspendSlice } from '../../../redux/slices/SuspendSlice';
import AddTaxes from './AddTaxes';
import AddDiscount from './AddDiscount';
import AddNote from './AddNote';
import Credit from './Credit';
import productApi from '../../../utils/services/api/productApi';

function Cart(props) {

  const [toast, setToast] = useContext(ToastContext);

  const currentUser = useSelector(user);
  const idStore = useSelector(idStoreCurrent);
  
  const [createOrder, isLoading] = useCreateOrderMutation();
  
  const dispatch = useDispatch();
  const panier = useSelector(getCarts);
  const item = useSelector(items);
  const totalSale = useSelector(total);
  const [code, setCode]= useState();
  const [discount, setDiscount] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [notes, setNotes] = useState('');
  const [methodePaiement, setMethodePaiement] = useState('cash');
  const [statutSale, setStatutSale] = useState('paid');
  const [montantVerser, setMontantVerser] = useState(totalSale)
  const [isOpen, setIsOpen] = useState(false);

  // Date vente 
  let today= new Date();
  const [dateVente]=useState(
    new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today)
  );

  useEffect(()=>{
     setCode(`INV-${randomNumberInRange(0, 99)}${randomNumberInRange(9, 999)}`);
  },[isLoading])

  const handlePay = (method) => {
    if(method==='cash'){
      setMethodePaiement('cash')
    }else if(method === 'card'){
      setMethodePaiement('creditCard')
    }else{
      setMethodePaiement('none')
    }
  }

  const renduMonnaie = ()=> {
    let monnaie=0;
        let versement = parseFloat(montantVerser);
        let rabais = parseFloat(discount);
        let taxe = parseFloat(taxes);

        if((totalSale+taxe) <(versement + rabais)){
            monnaie = (versement+rabais)-totalSale;
        }
        return monnaie;
  }

  const montantDue = () =>{
    let due=0;
    let versement = parseFloat(montantVerser);
    let rabais = parseFloat(discount);
    let taxe = parseFloat(taxes);

    if((totalSale+taxe)>(versement + rabais)){
        due = totalSale-(versement + rabais);
    }

    return due;
  }

  const etatStatut = () => {
    let etat= statutSale;
    let versement = parseFloat(montantVerser);
    let rabais = parseFloat(discount);
    let taxe = parseFloat(taxes);

    if((totalSale+taxe)>(versement + rabais)){
        if(versement>0){
            etat='pending';
        }else{
            etat = 'due';
        }
    }else{
      etat= 'paid'
    }
    
    return etat;
  }

  useEffect(()=>{
    if(statutSale==="due"){
        setMontantVerser(0)
        setDiscount(0)
        setMethodePaiement("none")
    }
  },[statutSale])

  useEffect(()=> {
    setMontantVerser(totalSale)
  }, [totalSale])

  // Dropdown menu pour Brouillon, susprendre, vider panier
  const handleDraft =()=> {
    dispatch(draftSlice.actions.addDraft({
      noFacture: code,
      cart: panier,
      date: dateVente,
      customerId: props.client.id,
      customerFullName : `${props.client.nom} ${props.client.prenom}`,
      items: item,
      montant: totalSale
    }))

    dispatch(cartSlice.actions.cleanCart())
    setToast({
      ...toast,
      isOpen: true,
      severity: 'info',
      message: 'Panier ajouter au brouillon avec succès'
    })
  }

  const handleSuspend = () => {
    dispatch(suspendSlice.actions.addSuspend({
      noFacture: code,
      cart: panier,
      date: dateVente,
      customerId: props.client.id,
      customerFullName : `${props.client.nom} ${props.client.prenom}`,
      items: item,
      montant: totalSale
      
    }))
    dispatch(cartSlice.actions.cleanCart())
    setToast({
      ...toast,
      isOpen: true,
      severity: 'info',
      message: 'Panier mise en suspension avec succès'
    })
  }

  const dropdown = () => (
    <div className="dropdown-menu dropdown-menu-end">
      <button className="dropdown-item" onClick={()=> handleSuspend()}>Suspendre</button>
      <button className="dropdown-item" onClick={()=> handleDraft()}>Brouillon</button>
      <button className="dropdown-item text-danger"
        onClick={()=> dispatch(cartSlice.actions.cleanCart())}>
          Vider panier
      </button>
    </div>
  );
  //

  // Printer
  const componentRef = useRef();
  const print = useReactToPrint({
      content: () => componentRef.current,
  });

  const handleSale=(e)=>{
    e.preventDefault();

    let order = {
      'noFacture':code,
      'customerId': props.client.id,
      'statut': etatStatut(),
      'methodePaiement': methodePaiement,
      'items': item,
      'montantVendu': totalSale,
      'montantVerser': montantVerser,
      'montantDue': montantDue(),
      'monnaie': renduMonnaie(),
      'taxes': taxes,
      'discount': discount,
      'statutExpedition': 'onSite',
      'noteVente': notes,
      'dateVente': dateVente,
      'userId': currentUser.id,
      'magasinId': idStore,
      'carts': panier
    }

    createOrder(order)
    .then((resp)=> {
      if(resp.data.message){
        setToast({
               ...toast,
               isOpen: true,
               severity: 'success',
               message: resp.data.message
           })

          // On reinitialise les varaibles saisies
           setDiscount(0);
           setTaxes(0);
           setMontantVerser(0);
           setNotes('');
           setStatutSale('paid');
           setMethodePaiement('cash');
          //  Si tout va bien, on vide le panier
          print();
          dispatch(cartSlice.actions.cleanCart());
          dispatch(productApi.util.invalidateTags(['Product']));
      }else if(resp.data.error){
       setToast({
           ...toast,
           isOpen: true,
           severity: 'danger',
           message:  resp.data.error
       })
      }else{
       setToast({
           ...toast,
           isOpen: true,
           severity: 'danger',
           message:  resp.data.errors[0].message
       })
      }
    }).catch((error)=> {
      console.log(error)
    })
  }

  // Section incrementation article
  const incrementQuantity = (id, qty, title)=> {
    const article = props.products?.find((item)=> item.id === id);

    if(article !== undefined){
      if(article.suiviStock && article.enStock === qty){
        setToast({
          ...toast,
          isOpen: true,
          severity: 'warning',
          message: 'Vous avez atteint la limite du stock!'
        })
      }else{
        dispatch(cartSlice.actions.incrementQuantity({ title }))
      }
    }
  }

  return (
    <div className='card'>
        <div className="card-header">
            <div className='d-block'>
                <h3 className="card-title">Panier</h3>
                <h5 className='card-subtitle'> Code: {code}</h5>
                <h5 className='card-subtitle'>Date: {dateVente}</h5>
            </div>
            <div className='card-actions'>
              <div className='dropdown'>
                <button className='btn btn-icon btn-action dropdown-toggle' data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-settings"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg>
                </button>
                {dropdown()}
              </div>
            </div>
        </div>

        
        <div className='card-body py-1'>
            <div className="list-group list-group-transparent mb-3" style={{maxHeight: '400px', minHeight: '250px', overflow: 'auto'}}>

                {panier.map((item, i)=> (
                  <div className="list-group-item list-group-item-action d-flex align-items-center" key={i}>
                      <div className="card" style={{width: '100%', height: '81px'}}>
                        <div className="row row-0">
                          <div className="col-auto">
                            <img src={item.image} className="rounded-start object-cover" alt="cover" width="80" height="80px"/>
                          </div>
                          <div className="col">
                            <div className="card-body">
                              {item.title} <span>{item.category}</span>
                              <div className='d-flex flex-row justify-content-between'>
                                <div className="text-muted">
                                  {item.price} $
                                </div>
                                <div className="btn-group w-10 me-2" role="group">
                                  <button type="button" className="btn btn-sm btn-icon" onClick={()=> 
                                      dispatch(cartSlice.actions.decrementQuantity({ title: item.title }))}>
                                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-minus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /></svg>
                                  </button>
                                  <button type="button" className="btn btn-sm">{item.qty}</button>
                                  <button type="button" className="btn btn-sm btn-icon"
                                    onClick={()=> incrementQuantity(item.articleId, item.qty ,item.title)}>
                                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                                  </button>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                ))}

                {panier.length===0 && (
                  <div className='d-flex align-items-center justify-content-center' style={{maxHeight: '400px', minHeight: '250px'}}>
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-shopping-cart-off"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17a2 2 0 1 0 2 2" /><path d="M17 17h-11v-11" /><path d="M9.239 5.231l10.761 .769l-1 7h-2m-4 0h-7" /><path d="M3 3l18 18" /></svg>
                    Panier vide
                  </div>
                )}

            </div>
        </div>

        <div className='card-footer'>
            <div className='w-100'>  
              <div style={{fontSize: '13px'}}>items: {item}x</div>
              {discount!==0 &&
              <div style={{fontSize: '13px'}}>Discount: {discount}$</div>}
              {taxes!==0 &&
              <div style={{fontSize: '13px'}}>Taxes: {taxes}$</div>}
              <div style={{fontSize: '13px'}}>SubTotal: {totalSale}$</div>
              <div className='text-muted'>Total: {(parseFloat(totalSale)+parseFloat(taxes))-parseFloat(discount)} $</div>
            </div>

          {/* La partie pour appliquer des modifications sur le montant total (discount, tax, shipping) */}
          <div className='mt-3'>
            <div className='d-flex flex-row gap-3'>
              <div className="d-flex flex-row align-items-center gap-1">
                <button className="btn btn-icon btn-ghost-primary" data-bs-toggle="modal" data-bs-target="#modal-discount">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                </button>
                <span style={{fontSize: '13px'}}>Discount</span>
              </div>
              <div className="d-flex flex-row align-items-center gap-1">
                <button className="btn btn-icon btn-ghost-primary" data-bs-toggle="modal" data-bs-target="#modal-taxes">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                </button>
                <span style={{fontSize: '13px'}}>Taxes</span>
              </div>
              <div className="d-flex flex-row align-items-center gap-1">
                <button className="btn btn-icon btn-ghost-primary" data-bs-toggle="modal" data-bs-target="#modal-notes">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                </button>
                <span style={{fontSize: '13px'}}>Notes</span>
              </div>
            </div>
          </div>

            <div className='mt-3'>
              <h3 style={{fontWeight: 'normal'}}>Paiements</h3>
              <div className="btn-group w-100" role="group">
                <button type="button" className={`btn btn-lg btn-icon d-flex flex-column pt-3 gap-1 
                  ${methodePaiement==='cash' && 'active'}`} onClick={()=> handlePay('cash')}>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-cash-banknote"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M3 6m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M18 12l.01 0" /><path d="M6 12l.01 0" /></svg>
                  <div style={{fontSize: 13}}>
                    Cash
                  </div>
                </button>
                <button type="button" className={`btn btn-lg btn-icon d-flex flex-column pt-3 gap-1 
                  ${methodePaiement==='creditCard' && 'active'}`} onClick={()=> handlePay('card')}>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-credit-card"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" /><path d="M3 10l18 0" /><path d="M7 15l.01 0" /><path d="M11 15l2 0" /></svg>
                  <div style={{fontSize: 13}}>
                      Carte
                  </div>
                </button>
                <button type="button" className={`btn btn-lg btn-icon d-flex flex-column pt-3 gap-1 
                  ${methodePaiement==='none' && 'active'}`} onClick={()=> handlePay('credit')} disabled={panier.length===0 ? true : false}
                  data-bs-toggle="modal" data-bs-target="#modal-credit">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-cash-banknote-off"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9.88 9.878a3 3 0 1 0 4.242 4.243m.58 -3.425a3.012 3.012 0 0 0 -1.412 -1.405" /><path d="M10 6h9a2 2 0 0 1 2 2v8c0 .294 -.064 .574 -.178 .825m-2.822 1.175h-13a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h1" /><path d="M18 12l.01 0" /><path d="M6 12l.01 0" /><path d="M3 3l18 18" /></svg>
                    <div style={{fontSize: 13}}>
                      Credit
                    </div>
                </button>
              </div>
            </div>
            

            <div className="mt-3">
                <button className="btn btn-primary w-100" onClick={handleSale} disabled={panier.length===0 ? true : false}>
                    Valider
                </button>
            </div>
        </div>
          
          <div style={{display: 'none'}}>
            <Invoice ref={componentRef} list={panier}
              item={item} totalSale={totalSale} discount={discount}
              taxes={taxes} salesPerson={currentUser.username} customer={`${props.client.nom} ${props.client.prenom} - ${props.client.tel}`}
              montantDue={montantDue} renduMonnaie={renduMonnaie} montantVerser={montantVerser}
            />
          </div>
          
          <AddNote notes={notes} setNotes={setNotes}/>
          <AddDiscount discount={discount} setDiscount={setDiscount}/>
          <AddTaxes taxes={taxes} setTaxes={setTaxes}/>
          <Credit handleSubmit={handleSale} setStatutSale={setStatutSale}
            montantDue={montantDue} renduMonnaie={renduMonnaie} 
            montantVerser={montantVerser} setMontantVerser={setMontantVerser} totalSale={totalSale}
            methodePaiement={methodePaiement} setMethodePaiement={setMethodePaiement}
            notes={notes} setNotes={setNotes} items={item} isOpen={isOpen} setIsOpen={setIsOpen}
          />
    </div>
  )
}

export default Cart