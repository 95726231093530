import { useContext, useEffect, useState } from 'react';
import Header from '../../../components/header-page/Header';
import { generatePagination } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { useDeletePaymentMutation, useGetPaymentsQuery } from '../../../utils/services/api/paymentApi';
import DeletePaiement from './mutation/DeletePaiement';
import { ToastContext } from '../../../utils/contexts/context';

function Paiements() {

  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    query: ''
  })

  useEffect(()=> {
    setCurrentPage(1)
  }, [filter])

  const {data: payments, isLoading} = useGetPaymentsQuery(filter);

  // Pour exporter en format Excel
  const handleExport = ()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(payments);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Liste_produits.xlsx');
  }

  // Pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = payments?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(payments?.length/recordsPerPage);
  const numbers = generatePagination(currentPage, npage)

  //  Pour les lignes selectionnées
  const [selected, setSelected] = useState([]);

  const handleAdd = () => {
    navigate('/orders/payments/ajouter')
  }

  const [toast, setToast] = useContext(ToastContext);
  const [deletePayment] = useDeletePaymentMutation();
  const handleDelete = (id) => {
    deletePayment(id)
    .then((resp)=> {
      if(resp.data.message){ 
        setToast({
          ...toast,
          isOpen: true,
          severity: 'success',
          message: 'Suppression réussie'
        })
      }else{
        setToast({
          ...toast,
          isOpen: true,
          severity: 'danger',
          message: resp.data.errors[0].message
        })
      }
    }).catch((error)=> {
      setToast({
        ...toast,
        isOpen: true,
        severity: 'danger',
        message: 'Erreur lors de la suppression'
    });
    })
  }

  return (
    <div className="page-wrapper">
        
        <Header title={'Liste de Paiements'}/>

        <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Remboursement</h3>
                    <div className='ms-auto'>
                      {selected.length > 0 &&
                        <button className='btn btn-icon btn-ghost-danger' data-bs-toggle="modal" data-bs-target="#modal-danger" style={{ marginRight: '5px' }}>
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                        </button>
                      }

                      <button className='btn btn-icon' data-bs-toggle="modal" data-bs-target="#modal-team" style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-filter"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" /></svg>
                      </button>

                      <button className='btn btn-icon btn-success' onClick={()=>handleExport()} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Exportez en format Excel" style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-spreadsheet"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M8 11h8v7h-8z" /><path d="M8 15h8" /><path d="M11 11v7" /></svg>
                      </button>

                      <button className="btn btn-primary" onClick={handleAdd}>Ajouter</button>

                    </div>
                  </div>
                  <div className="card-body border-bottom py-3">
                    <div className="d-flex">
                      <div className="text-muted">
                        Show
                        <div className="mx-2 d-inline-block">
                            <select class="form-select" className="form-control form-control-sm" onChange={(e)=> setRecordsPerPage(e.target.value)}>
                              <option value={10}>10</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                        </div>
                        entries
                      </div>
                      <div className="ms-auto text-muted">
                        Search:
                        <div className="ms-2 d-inline-block">
                         <input type="text" className="form-control form-control-sm" 
                          onChange={(e)=> setFilter({...filter, query: e.target.value})}
                          aria-label="Search invoice"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table card-table table-vcenter text-nowrap datatable">
                      <thead>
                        <tr>
                          <th className="w-1">
                            <input className="form-check-input m-0 align-middle" type="checkbox" 
                            checked={selected.length === payments?.length && payments?.length>0} 
                            onChange={(event) => {
                              setSelected(
                                event.target.checked ? payments?.map((row) => row.id) : [],
                              );
                            }}
                            aria-label="Select all invoices"/>
                          </th>
                          <th>Code</th>
                          <th>Client</th>
                          <th>Montant</th>
                          <th>Balance</th>
                          <th>Methode Paiements</th>
                          <th>No Facture</th>
                          <th>Date vente</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoading && 
                          <tr>
                            <td colspan={10} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                          </tr>
                        }
                        {records?.map((payment, i)=>(
                        <tr key={i}>
                          <td><input className="form-check-input m-0 align-middle" type="checkbox" 
                          checked={selected.includes(payment.id)}
                          onChange={(event) => {
                            setSelected((ids) =>
                              event.target.checked
                                ? ids.concat(payment.id)
                                : ids.filter((itemId) => itemId !== payment.id),
                            );
                          }}
                          aria-label="Select invoice"/></td>
                          <td>{payment.code}</td>
                          <td>{payment.fullname}</td>
                          <td>{payment.mtt}</td>
                          <td>{payment.balance}</td>
                          <td>{payment.methodePaiement}</td>
                          <td>{payment.noFacture}</td>
                          <td>{dayjs(payment.data).format('DD/MM/YYYY')}</td>
                          <td className="text-end">
                              <button className="btn btn-icon btn-ghost-secondary" onClick={()=> handleDelete(payment.id)}>
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" /><path d="M12 9v4" /><path d="M12 17h.01" /></svg> */}
                              </button>
                          </td>
                        </tr>
                        ))}

                       {payments?.length === 0 &&
                          <tr>
                            <td colspan={10} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer d-flex align-items-center">
                    <p className="m-0 text-muted">Showing <span>{currentPage}</span> to <span>{npage}</span></p>
                    <ul className="pagination m-0 ms-auto">
                      <li className="page-item">
                        <button onClick={prePage} className={`page-link ${currentPage===1 && 'disabled'}`} tabindex="-1">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" stroke-linecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                        </button>
                      </li>
                      {!isLoading && (numbers?.map((page, id)=> (
                        <li className={`page-item ${currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
                      )))}
                      <li className="page-item">
                        <button onClick={nextPage} className={`page-link ${currentPage===npage && 'disabled'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeletePaiement ids={selected} setIds={setSelected}/>
    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }
}

export default Paiements