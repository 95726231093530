import { useContext } from 'react';
import {Outlet} from 'react-router-dom';
import { ToastContext } from '../../utils/contexts/context';
import Toast from '../../components/toast/Toast';

function AuthLayout() {
    const [toast, setToast] = useContext(ToastContext);

    return <div>
        <Outlet/>
        <Toast toast={toast} setToast={setToast}/>
    </div>
}

export default AuthLayout