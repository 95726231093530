import React from 'react'

function FilterArticle({filter, setFilter, data}) {

    // Ceci permet de filter le tableau de categories pour eliminer les doublons
    const categoriesDoublons = data?.reduce((acc, item)=> 
        acc.includes(item.nomCategorie) ? acc : acc.concat(item.nomCategorie),
    [])
    const categoriesTrie = []
    const categories = categoriesDoublons?.filter((item)=>{
        if(!categoriesTrie.includes(item)){
        categoriesTrie.push(item)
        return true
        }
        return false
    })

    // Ceci permet de filter le tableau de magasin pour eliminer les doublons
    const magasinsDoublons = data?.reduce((acc, item)=> 
        acc.includes(item.nomStore) ? acc : acc.concat(item.nomStore),
    [])
    const magasinsTrie = []
    const magasins = magasinsDoublons?.filter((item)=>{
        if(!magasinsTrie.includes(item)){
        magasinsTrie.push(item)
        return true
        }
        return false
    })

  return (
    <div className="modal modal-blur fade" id="modal-team" tabindex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filtre</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div class="mb-1">
                <label className="form-label">Magasins</label>
                <select className="form-select" onChange={(e)=> setFilter({...filter, nomStore: e.target.value})}>
                    <option value="">All</option>
                    { 
                        magasins?.map((nomStore, i)=> (
                            <option value={nomStore} key={i}>{nomStore}</option>
                        ))
                    }
                </select>
            </div>
            <div class="mb-1">
                <label className="form-label">Categories</label>
                <select className="form-select" onChange={(e)=> setFilter({...filter, nomCategorie: e.target.value})}>
                    <option value="">All</option>
                    { 
                        categories?.map((nomCategory, i)=> (
                            <option value={nomCategory} key={i}>{nomCategory}</option>
                        ))
                    }
                </select>
            </div>

            <div class="mb-1">
                <label className="form-label">Statut</label>
                <select className="form-select" onChange={(e)=> setFilter({...filter, statut: e.target.value})}>
                    <option value="">All</option>
                    <option value={true}>En vente</option>
                    <option value={false}>Brouillon</option>
                </select>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn me-auto" data-bs-dismiss="modal">Close</button>
            {/* <button type="button" className="btn btn-primary" data-bs-dismiss="modal">filter</button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterArticle